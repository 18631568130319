import api from "../api";

export const sendMessageToAdmin = async (name, email, message) => {
  try {
    const response = await api.post("/contact", { name, email, message });

    if (response.status !== 200) {
      throw new Error("Failed to send email");
    }

    return response.data;
  } catch (error) {
    throw error.message;
  }
};
