import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: 33px;

    p {
        font-size: 14px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.80);
        margin-bottom: 12px;
    }
`;

export const ProgressBar = styled.div`
    height: 8px;
    position: relative;
    background: #E2C784;
`;

export const ProgressRed = styled.div`
    height: 8px;
    position: absolute;
    background: #C16060;
    width: 33%;
`;

export const ProgressGreen = styled.div`
    height: 8px;
    position: absolute;
    background: #73A56F;
    width: 33%;
    right: 0;
`;

export const ReportStatus = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    text-align: center;
    min-width: 10px;
    padding: 4px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;

    &.REQUESTED {
        background-color: gray;
    }

    &.IN_PROGRESS {
        background-color: yellow;
        color: black;
    }

    &.COMPLETED {
        background-color: green;
    }

    @media (max-width: 680px) {
        padding: 2px;
        width: 100px;
        margin-left: 2px;
        margin-right: 2px;

        span {
            display: none;
        }
    }

    span {
      font-size: 0.9em;
      margin-left: 5px;
      color: inherit; 
    }
`;