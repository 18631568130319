import React, {useRef, useState} from 'react';
import FaceDetection from '../components/FaceDetection/FaceDetection';


function FaceDetectionPage() {
  const webcamRef = useRef(null);
  const [status, setStatus] = useState({
    faceDetected: false,
    inPosition: false,
    goodLighting: false
  });

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    console.log("Face status:", newStatus);
  };


  return (
    <div className="App">
      <h1>Face Analysis Web App</h1>


      <div className="container"
           style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px', maxWidth: '1200px'}}>
        <FaceDetection
          webcamRef={webcamRef}
          mirror={false}
          facingMode="user"
          onStatusChange={handleStatusChange}
        />
      </div>
    </div>
  );
}

export default FaceDetectionPage;
