import {useState} from "react";
import {useZxing} from "react-zxing";

export const RegisterKitBarcode = ({setCode}) => {
    const [result, setResult] = useState("");
    const {ref} = useZxing({
        onDecodeResult(result) {
            setResult(result.getText());
            setCode(result.getText());
        },
    });

    return (
        <>
            <video ref={ref} style={{
                height: "auto"
            }}/>
        </>
    );
};