import React from "react";
import {
  CloseButton,
  InfoModalText,
  InfoModalTitle,
  InfoModalWrapper,
} from "./InfoModal.styles";

const InfoModal = ({ isOpen, onClose,title,subText }) => {
  return (
    <InfoModalWrapper isOpen={isOpen}>
      <InfoModalTitle>
        {title} <CloseButton onClick={onClose}>X</CloseButton>
      </InfoModalTitle>
      <InfoModalText>
        {subText}
      </InfoModalText>
    </InfoModalWrapper>
  );
};

export default InfoModal;
