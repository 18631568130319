import api from "../api";

export const getAllPatients = async (doctorId) => {
    try {
        const response = await api.get(`/patients/${doctorId}`, {
            credentials: "include",
        });

        if (!(response.status === 200)) {
            throw new Error("Network response was not ok");
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const getPatient = async (patientId) => {
    try {
        const response = await api.get(`/patient/${patientId}`, {
            credentials: "include",
        });

        if (!(response.status === 200)) {
            throw new Error("Network response was not ok");
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const createPatient = async (
    doctorId,
    firstName,
    lastName,
    email,
    phone
) => {
    try {
        const response = await api.post(
            "/patients",
            {doctorId, firstName, lastName, email, phone},
            {
                credentials: "include",
            }
        );

        if (!(response.status === 201)) {
            throw new Error("Network response was not ok");
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const searchPatients = async (doctorId, search) => {
    try {
        const response = await api.get(`/patients/search/${doctorId}`, {
            params: {
                q: search,
            },
        });

        if (!(response.status === 200)) {
            throw new Error("Network response was not ok");
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const getReportRequestsByPatient = async (patientId) => {
    try {
        const response = await api.get(`/report_requests/${patientId}`, {
            credentials: "include",
        });

        if (!(response.status === 200)) {
            throw new Error("Network response was not ok");
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const createReportRequest = async (patientId, doctorId) => {
    try {
        const response = await api.post("/report_requests", {
            requested_for: patientId,
            requested_by: doctorId,
        });

        if (response.status !== 201) {
            throw new Error("Failed to create report request");
        }

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error; // Throw the whole error instead of error.message
    }
};

export const createSurvey = async (patientId, doctorId, surveyData) => {
    try {
        const response = await api.post("/surveys", {
            patient_id: patientId,
            doctor_id: doctorId,
            survey_response: surveyData,
        });

        if (response.status !== 201) {
            throw new Error("Failed to Submit Survey");
        }
        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
};

export const createStandaloneSurvey = async (surveyData, patient_id) => {
    try {
        const response = await api.post("/surveys", {
            patient_id,
            survey_response: surveyData,
        });

        if (response.status !== 201) {
            throw new Error("Failed to Submit Survey");
        }
        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
};

export const getSurveysByPatientId = async (patientId) => {
    try {
        const response = await api.get(`/surveys/${patientId}`, {
            credentials: "include",
        });

        if (response.status !== 200) {
            throw new Error("Failed to fetch surveys");
        }

        return response.data;
    } catch (error) {
        console.error("API error:", error);
        throw error;
    }
};

export const getSurveysByPatientandSurveyId = async (patientId, surveyId) => {
    try {
        const response = await api.get(`/surveys/${patientId}/${surveyId}`, {
            credentials: "include",
        });

        if (response.status !== 200) {
            throw new Error("Failed to fetch surveys");
        }

        return response.data;
    } catch (error) {
        console.log("API Error", error);
        throw error;
    }
};
