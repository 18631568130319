import styled from "styled-components";

export const FullScreeen = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #2c1d37;
  color: white;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 16px;
    gap: 12px;
  }
`;