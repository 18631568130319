import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AddIcon from "../../assets/new-test.svg";
import ProfileImage from "../../assets/profile-img.svg";
import CloseModal from "../CloseModal";
import { AddImg, ProfileImg, Wrapper, ImgModal } from "./AddClientImage.styles";
import { updateDoctorProfilePicture } from "../../utils/api-lists/doctor.api";
import { useTranslation } from "react-i18next";

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 67;

const AddClientImage = ({ showText, imgUrl,doctorId,onImageChange }) => {
  const [image, setImage] = useState(imgUrl || ProfileImage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const {t} = useTranslation('common');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const url = URL.createObjectURL(file);
    setImage(url); 
    setIsModalOpen(true);
  };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const handleCropComplete = (crop) => {
    setCompletedCrop(crop);
  };

  const onImageLoaded = (img) => {
    imgRef.current = img;
    const { width, height } = img;
    const crop = makeAspectCrop({ unit: "%", width: 65 }, ASPECT_RATIO, width, height);
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const getCroppedImage = async () => {
    if (!imgRef.current || !completedCrop) return;

    const canvas = document.createElement("canvas");
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "cropped-image.jpg", { type: "image/jpeg" });
          resolve(file);
        }
      }, "image/jpeg");
    });
  };

  const handleSave = async () => {
    const croppedFile = await getCroppedImage();
    setImage(URL.createObjectURL(croppedFile));
    setIsModalOpen(false);
    onImageChange(URL.createObjectURL(croppedFile));

    await updateDoctorProfilePicture(doctorId, croppedFile);
  };

  return (
    <Wrapper>
      {showText && <p>Add client image</p>}
      <ProfileImg src={image} alt="Patient" />
      <label>
        <input type="file" accept="image/*" onChange={handleImageUpload} hidden />
        <AddImg src={AddIcon} alt="Add" />
      </label>
      <ImgModal isOpen={isModalOpen}>
        {image && (
          <ReactCrop
            crop={crop}
            onChange={handleCropChange}
            onComplete={handleCropComplete}
            aspect={ASPECT_RATIO}
            keepSelection={true}
            circularCrop={true}
            minWidth={MIN_DIMENSION}
            minHeight={MIN_DIMENSION}
          >
            <img ref={imgRef} src={image} alt="Preview" onLoad={(e) => onImageLoaded(e.target)} />
          </ReactCrop>
        )}
        
          <button type="button" className="btn btn-3" onClick={handleSave}>{t("save")}</button>
       
        <CloseModal onClick={() => setIsModalOpen(false)} />
      </ImgModal>
    </Wrapper>
  );
};

export default AddClientImage;
