import React, {useContext, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {ImgContainer, LinkContainer, LinksContainer, LogoImg, SideMenuHeader, Wrapper} from "./SideMenu.styles";
import {UserContext} from '../../context/UserContext';

import Logo from "../../assets/logo.svg";
import HomeIcon from "../../assets/home.svg";
import PatientListIcon from "../../assets/patient-list.svg";
import ProfileIcon from "../../assets/profile.svg";
import HelpIcon from "../../assets/Help.svg";
import LogoutIcon from "../../assets/logout.svg";
import {useMediaQuery} from "react-responsive";
import {FaBars} from "react-icons/fa";
import {useTranslation} from "react-i18next";

const SideMenu = () => {
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const {logoutUser} = useContext(UserContext);
  const isMobile = useMediaQuery({query: `(max-width: 800px)`});
  const [showSideMenu, setShowSideMenu] = useState(false);

  const isCurrentPage = (path) => {
    return location.pathname === path;
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/login');
  };

  return (
    <Wrapper className={!showSideMenu && isMobile ? 'mobile-view' : null}
             style={{width: !showSideMenu && isMobile ? 64 : 230, transition: "all 0.5s"}}>
      <SideMenuHeader className={'hamburger'}>
        {(!isMobile) && <LogoImg onClick={() => navigate('/')} src={Logo} alt="logo"/>}
        {(showSideMenu && isMobile) &&
          <LogoImg onClick={() => navigate('/')} src={Logo} alt="logo" style={{width: 96}}/>}
        {isMobile && <FaBars onClick={() => {
          setShowSideMenu(!showSideMenu)
        }} size={20} className={'mt-1'}/>
          // <Icon onClick={()=>{setShowSideMenu(!showSideMenu)}} icon="icon-park-outline:hamburger-button" height="28" />
        }
      </SideMenuHeader>
      <LinksContainer style={{flexGrow: 1}}>
        <LinkContainer onClick={() => navigate('/dashboard')}
                       className={isCurrentPage('/dashboard') ? 'active-page' : ''}>
          <ImgContainer>
            <img src={HomeIcon} alt="home"/>
          </ImgContainer>
          <p>{t('home')}</p>
        </LinkContainer>
        <LinkContainer onClick={() => navigate('/patient-list')}
                       className={isCurrentPage('/patient-list') ? 'active-page' : ''}>
          <ImgContainer>
            <img src={PatientListIcon} alt="{t('patient_list')}"/>
          </ImgContainer>
          <p>{t('patient_list')}</p>
        </LinkContainer>
        <LinkContainer onClick={() => navigate('/profile')}
                       className={isCurrentPage('/profile') ? 'active-page' : ''}>
          <ImgContainer>
            <img src={ProfileIcon} alt="Profile"/>
          </ImgContainer>
          <p>{t('profile')}</p>
        </LinkContainer>
        <LinkContainer onClick={() => navigate('/help-page')}
                       className={isCurrentPage('/help-page') ? 'active-page' : ''}>
          <ImgContainer>
            <img src={HelpIcon} alt="Help"/>
          </ImgContainer>
          <p>{t('help')}</p>
        </LinkContainer>
      </LinksContainer>
      <LinksContainer style={{marginBottom: '50px'}}>
        <LinkContainer onClick={handleLogout}>
          <ImgContainer>
            <img src={LogoutIcon} alt="log out"/>
          </ImgContainer>
          <p>{t('log_out')}</p>
        </LinkContainer>
      </LinksContainer>
    </Wrapper>
  )
};

export default SideMenu;