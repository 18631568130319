import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {skincareRoutine} from "../../assets/data/skincare-routine";
import {ReportProduct} from "../../components/ReportProduct";
import Accordion from "../../widgets/accardion";
import SkincareGuide from "./skin_indexs/skincare_guide";
import TimeTag from "../../components/Tags";


const PersonalGrowTab = ({report, products}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentProducts, setCurrentProducts] = useState(null);

  let sliderRef = useRef(null);

  function getTabContent(currentTab, product) {
    const currentRoutine = skincareRoutine.find(
      (routine) => routine.type === product.category.name
    );
    switch (currentTab) {
      default:
      case 0:
        return <p>{product.description}</p>;
      case 1:
        return <p>{currentRoutine?.why}</p>;

      case 2:
        return <p>{currentRoutine?.how}</p>;
    }
  }

  function selectTab(tab) {
    setCurrentTab(tab);
  }

  const handleSliderChange = (index) => {
    setActiveIndex(index);
  };

  const handleStepClick = (index) => {
    setActiveIndex(index);
    sliderRef.current.slickGoTo(index)
    setCurrentTab(0)
  };

  useEffect(() => {
    if (products) {
      updateProductsList(report["data"]["products"]);
    }
  }, [report, products]);

  const updateProductsList = (productsIdList) => {
    console.log('updated', productsIdList);
    let temp = [];
    for (let i = 0; i < productsIdList.length; i++) {
      let product = products.find(
        (product) => product.id === productsIdList[i]
      );
      if (product) {
        temp.push(product);
      }
    }
    setCurrentProducts(temp);
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: handleSliderChange,
    draggable: false,
    swipe: false
  };

  const allProducts = [
    ...(currentProducts?.filter(product => product.application_time.includes(1)).map(product => ({
      ...product,
      appTime: "morning"
    })) || []),
    ...(currentProducts?.filter(product => product.application_time.includes(2)).map(product => ({
      ...product,
      appTime: "evening"
    })) || []),
  ];

  return (
    <div
      className="flex flex-wrap gap-3 m-auto vertical-layout"
      style={{maxWidth: "1300px"}}
    >
      <div className="flex flex-col gap-3 flex-1">
        <Accordion
          closable={false}
          title={"Your Step-by-Step Personalized Skincare Guide"}
          children={<SkincareGuide products={currentProducts}
                                   onStep={handleStepClick}
                                   allProducts={products}
                                   updateProductList={updateProductsList}
                                   report={report}/>}
        ></Accordion>
      </div>
      <div className="flex flex-col gap-3 flex-1">
        <Accordion

          title={"Your Personalized Skincare Routine"}
          children={
            <Slider {...settings} ref={sliderRef} style={{width: "100%"}}>
              {allProducts && allProducts.length > 0 ? (
                allProducts.map((product, index) => {
                  return (
                    <div>
                      <div className="flex flex-raw gap-1 justify-center">
                        <div style={{width: "240px"}}>
                          <ReportProduct
                            className=""
                            tags={product.ingredients}
                            price={product.budget}
                            day_night={product.application_time.map((time) =>
                              time === 1 ? "day" : "night"
                            )}
                            priceIcons={product.budget}
                            product_img={product.image_url}
                            name={product.name}
                          />
                        </div>
                        <div className="flex flex-col gap-3 w-full border-l p-3 px-5 flex-1">
                          <div className="flex justify-start mb-6">
                            {product.appTime === "morning" ? <TimeTag type={"morning"}/> : <TimeTag type={"evening"}/>}
                          </div>
                          <div className="flex flex-row justify-center mt-6">
                            <div className="flex flex-row gap-3 p-3 tab-widget-buttons">
                              <button
                                className={currentTab === 0 ? "selected" : ""}
                                onClick={() => selectTab(0)}
                              >
                                Info
                              </button>
                              <button
                                className={currentTab === 1 ? "selected" : ""}
                                onClick={() => selectTab(1)}
                              >
                                Why?
                              </button>
                              <button
                                className={currentTab === 2 ? "selected" : ""}
                                onClick={() => selectTab(2)}
                              >
                                How?
                              </button>
                            </div>
                          </div>
                          <TransitionGroup className="content-wrapper w-full">
                            <CSSTransition
                              key={currentTab}
                              classNames="content"
                              timeout={500}
                            >
                              {
                                <div className="w-full">
                                  {getTabContent(currentTab, product)}
                                </div>
                              }
                            </CSSTransition>
                          </TransitionGroup>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <p>No products found</p>
                </div>
              )}
            </Slider>
          }
        ></Accordion>
      </div>
    </div>
  );
};

export default PersonalGrowTab;
