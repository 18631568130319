import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

// Components
import {DashboardWrapper} from '../components/DashboardWrapper/DashboardWrapper.styles';
import Input from '../components/Input';
import TextArea from "../components/textarea";
import {Form} from "../components/NewPatient/NewPatient.styles";
import {sendMessageToAdmin} from '../utils/api-lists/contact.api';
import Alert from '../components/Alert';
import {Button} from "antd";

const HelpPage = () => {
  const {t} = useTranslation('common');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendMessageToAdmin(name, email, message);
      setSuccess(t("email_sent"));
      setName('');
      setEmail('');
      setMessage('');
    } catch (err) {
      setError(err);
      setSuccess(null);
      setName('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <DashboardWrapper>
      {success && <Alert type="success" message={success}/>}
      {error && <Alert type="error" message={error}/>}
      <div style={{textAlign: 'center', maxWidth: '60%', margin: 'auto'}}>
        <h1>{t('title')}</h1>
        <p>{t('description')}</p>
        <h1 className='padding-top-24'>{t('contact')}</h1>
        <div className='mt-3'>
          <p>Email: contact@glacies.bio</p>
          <p>Phone: +352 661 819 918</p>
        </div>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder={t('name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder={t('email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextArea
            name="message"
            placeholder={t('message')}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          {/*<button type="submit" className="btn btn-2 btn-align-right"></button>*/}
          <div>
            <Button type="primary" htmlType="submit" size="large" style={{minWidth: '12rem'}}>{t('submit')}</Button>
          </div>
        </Form>
      </div>
    </DashboardWrapper>
  );
};

export default HelpPage;
