import styled from "styled-components"
import Modal from "styled-react-modal";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -18px;
  position: relative;
`

export const ProfileImg = styled.img`
  height: 67px;
  width: 67px;
  margin-left: 24px;
  border-radius: 50%; /* Make it circular */
  object-fit: cover; /* Ensure image fills the space without distortion */
  object-position: center; /* Keep the image centered */
`

export const AddImg = styled.img`
  height: 23px;
  width: 23px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
`

export const ImgModal = Modal.styled`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #333;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  width: 80%;
  height: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: auto; /* Allow height to adjust proportionally */
    object-fit: contain; /* Preserve aspect ratio */
    max-height: 65vh; /* Optional: Limit the height to 80% of the viewport */
    border-radius: 10px;
  }

  .bottomModal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: white;
  }
`;

