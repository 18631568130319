import React, {useCallback, useContext, useEffect, useState} from 'react';
import {DashboardWrapper} from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {Header, StyledModal} from "../styles";
import {useNavigate, useParams} from "react-router-dom";
import {GeneralBody} from "../../../widgets/GeneralBody";
import api from "../../../utils/api";
import {Button, Popup} from "../../../components/PatientProfile/PatientProfile.styles";
import CloseModal from "../../../components/CloseModal";
import {ListItemDiv} from "../../../widgets/ListItem/ListItem.style";
import {format} from "date-fns";
import {UserContext} from "../../../context/UserContext";
import {useTranslation} from "react-i18next";
import {RegisterKitContext} from "./RegisterKitContext";
import RegisterKitForm from "./register-kit-form";
import { formatDate } from '../../../utils/date.util';

const PatientRegisterKit = () => {
    const navigate = useNavigate();
    const registerKitContext = useContext(RegisterKitContext);
    const userContext = useContext(UserContext);

    const {patientId} = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [registerKits, setRegisterKits] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState(false);
    const [requestMessage, setRequestMessage] = useState('');
    const {t} = useTranslation('common');

    const fetchKits = useCallback(async () => {


        try {
            setLoading(true);
            const response = await api.get(`/patients/${patientId}/kits`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch reports');
            }

            const data = await response.data;
            setRegisterKits(data['kits']);
            registerKitContext.setRegisterKits(data['kits']);
            console.log('data', data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }, [patientId]);

    const openSkinReport = (reportId) => {
        navigate(`${reportId}`);
    }

    const createReport = async () => {
        try {
            const response = await api.post(`/report_requests`,
                {
                    requested_for: patientId,
                    requested_by: userContext.user.id,
                }
            );

            setIsRequestCreated(true);
            if (response.status !== 201) {

                setIsRequestCreated(true);
                setRequestMessage('Failed to create report request');
                throw new Error('Failed to create report request');
            }
            setRequestMessage('Report request created successfully');
        } catch (error) {

            console.log('userContext', userContext.user);

            setIsRequestCreated(true);
            setRequestMessage('Failed to create report request');
        }
    }

    function handleSubmit(barcode) {
        ///kits/assign
        api.post(
            "/kits/assign",
            {
                barcode: barcode,
                patient_id: patientId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => {
            console.log(response);
            fetchKits();
            setIsRequestCreated(false);
        }).catch((error) => {
            console.log(error)
        });
    }

    useEffect(() => {
        if (patientId) {
            fetchKits();
        }
    }, [patientId, fetchKits]);

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>{t("register_a_kit")}</h3>
                <Button
                    className="max-w-44"
                    onClick={() => {
                        createReport();
                    }}>{t("register_new_kit")}</Button>
            </Header>
            <GeneralBody>
                {
                    registerKits && registerKits.map((item, index) => {
                        return (
                            <ListItemDiv
                                key={index}
                                onClick={() => openSkinReport(item['id'])}
                            >
                                <p>{t("registered_kit")} {index}</p>
                                <h4>{item['kit_id']}</h4>
                                <p>{formatDate(item["registered_at"])}</p>
                            </ListItemDiv>
                        );
                    })
                }
            </GeneralBody>
            <StyledModal
                isOpen={isRequestCreated}
                onBackgroundClick={() => setIsRequestCreated(false)}
                onEscapeKeydown={() => setIsRequestCreated(false)}>
                <Popup>
                    <RegisterKitForm handleSubmit={handleSubmit}/>
                </Popup>
                <CloseModal onClick={() => {
                    setIsRequestCreated(false);
                }}/>
            </StyledModal>
        </DashboardWrapper>
    );

}

export default PatientRegisterKit;
