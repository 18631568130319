import styled from "styled-components";
import Modal from "styled-react-modal";

export const InfoModalWrapper = Modal.styled`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  background: white;
  border-radius: 6px;
  padding: 20px;
  width: 500px;
  height: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

 @media (max-width: 520px) {
    width: 90%;
  }

`;

export const InfoModalTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  width: 100%;
  margin-bottom: 10px;
`;

export const InfoModalText = styled.p`
  font-size: 16px;
  font-weight: lighter;
  color:rgb(0, 0, 0);
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: justify;
`;

export const CloseButton = styled.div`
color: purple;
  font-size: 20px;
  font-weight: lighter;
  cursor: pointer;
  align-self: flex-end;
`;