import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardWrapper } from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import { getSurveysByPatientandSurveyId } from "../../../utils/api-lists/patient.api";
import { GeneralBody } from "../../../widgets/GeneralBody";
import { Header } from "../styles";
import { formFields } from "../../../assets/data/survey/survey-form-field";
import {
  FormContainer,
  FormGroup,
  FormWrapper,
  Input,
  Label,
  Title,
} from "../../../components/PatientSurvey/PatientSurvey.styles";

const PatientSurveyDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { patientId, surveyId } = useParams();

  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    try {
      getSurveysByPatientandSurveyId(patientId, surveyId).then((data) => {
        setSurvey(data);
      });
    } catch (error) {
      console.log();
    }
  }, [surveyId]);

  const surveyResponse = survey?.survey_response
    ? JSON.parse(survey.survey_response)
    : null;

  console.log(surveyResponse);

  const formattedResponse = surveyResponse
    ? Object.entries(surveyResponse).map(([key, value]) => {
        const field = formFields.find((f) => f.name === key);
        const label = field ? field.label : key;

        const formattedValue = Array.isArray(value) ? value.join(", ") : value;

        return { label, value: formattedValue };
      })
    : null;

  console.log(formattedResponse);

  return (
    <DashboardWrapper>
      <Header>
        <IconContext.Provider value={{ style: { cursor: "pointer" } }}>
          <FaArrowLeft onClick={() => navigate(-1)} />
        </IconContext.Provider>
        <h3>{t("survey_details")}</h3>
      </Header>
      <GeneralBody>
        <FormContainer>
          <FormWrapper>
            <Title>{t("glacies_biome_questionare_response")}</Title>

            {formattedResponse&&formattedResponse.map((field, index) => (
              <FormGroup key={index}>
                <Label>{t(field.label)}</Label>
                <Input
                  type="text"
                  name={field.value}
                  value={t(field.value)}
                  disabled={true}
                />
              </FormGroup>
            ))}
            <FormGroup></FormGroup>
          </FormWrapper>
        </FormContainer>
      </GeneralBody>
    </DashboardWrapper>
  );
};

export default PatientSurveyDetails;
