import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  height: 50px;
  background: #2775ce;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  transition: background 0.3s ease;
  margin-left: 8px;

  &:hover {
    background: #1e5ea7;
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }


  @media (max-width: 520px) {
    font-size: 14px;

    svg {
      display: none; 
    }
  }
`;
