import styled from "styled-components";

export const FeaturesSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 1380px) {
    flex-direction: column;
    align-items: start;
  }
`;


export const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
  margin-top: 50px;
   @media (max-width: 1380px) {
   margin-bottom: 50px;
   justify-content: start;
  }
  
`;

export const Section = styled.div`
`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
  text-align: ${(props) => (props.align === "left" ? "left" : "right")};
    @media (max-width: 1380px) {
   text-align: start;
  }
`;


export const Divider = styled.div`
  width: 2px;
  height: 150px;
  background-color: #6e59c7;
    @media (max-width: 1380px) {
    display: none;
  }
`;

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
    @media (max-width: 1380px) {
    display: none;
  }
`;

