import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: -18px;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    //flex-wrap: wrap;
    justify-content: center;
    //align-items: center;
    //background-color: #373249;
    //padding: 20px;
    padding-top: 20px;
    border-radius: 10px;

    .cameraContainer {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    .imagePreview {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        border-radius: ;
        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    .webcam {
        width: 100%;
        height: 100%;
    }
`;

export const CamControls = styled.div`
    //position: absolute;
    //z-index: 2;
    bottom: 24px;
    right: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

