import React from "react";
import styled from "styled-components";

export const DiversityIndexBar = ({percentage = 0}) => {
    return (
        <Bar>
            <div className="progress-container">
                <div className="marker" style={{left: `${percentage}%`}}></div>
                <div className="value-label" style={{left: `${percentage}%`}}>
                    {percentage.toFixed(2)}
                </div>
            </div>
        </Bar>
    );
};

const Bar = styled.div`
    width: 100%;
    height: 40px;

    .progress-container {
        position: relative;
        width: 100%;
        height: 3px;
        border-radius: 5px;
        background: linear-gradient(
                to right,
                red 0%,
                orange 30%,
                #00f100 55%,
                orange 90%,
                red 100%
        );
    }

    .marker {
        position: absolute;
        top: -10px;
        transform: translateX(-50%);
        width: 3px;
        height: 20px;
        background: blueviolet;
        border-radius: 5px;
    }

    .value-label {
        position: absolute;
        top: 14px;
        transform: translateX(-50%);
        font-size: 14px;
        color: blueviolet;
    }
`;

export default DiversityIndexBar;
