import React, { useContext } from "react"
import { Wrapper, ProfileImg } from "./Profile.styles"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/UserContext"

import ProfileImage from "../../assets/profile-img.svg"
import AddIcon from "../../assets/new-test.svg"

const Profile = () => {
  const { user } = useContext(UserContext)
  const navigate = useNavigate()

  if (!user) {
    return <p>No user information available</p>
  }

  return (
    <Wrapper onClick={() => navigate("/profile")} style={{ cursor: "pointer" }}>
      <div >
        <p>{user.firstName}</p>
      </div>
      <ProfileImg src={user.profile_picture} alt="Profile" />
     
    </Wrapper>
  )
}

export default Profile
