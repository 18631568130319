import React, { useState } from "react";
import {TbInfoSquareRoundedFilled} from "react-icons/tb";
import styled from "styled-components";
import InfoModal from "../../../components/InfoModal";

export const TooltipWidget = ({message,title}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <TooltipContainer>
      <TbInfoSquareRoundedFilled className={'icon'} onClick={toggleModal}/>
      <InfoModal isOpen={isModalOpen} onClose={toggleModal} title={title} subText={message}/>
    </TooltipContainer>
  );
};

export default TooltipWidget;

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;

    * {
        color: #a1a2ff !important;
    }

    .icon {
        cursor: pointer;
    }

    &:hover .tooltip-message {
        visibility: visible;
        opacity: 1;
    }
`;

const TooltipMessage = styled.div`
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    font-size: 12px;
    z-index: 999;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    top: 100%; /* Position the tooltip below the icon */
    left: 0; /* Align the tooltip to the left of the icon */
    margin-left: -100px; /* Adjust the tooltip to the left */
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: "";
        position: absolute;
        bottom: 100%; /* Arrow at the top of the tooltip */
        left: 90%; /* Position the arrow near the right edge of the tooltip */
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #555 transparent;
    }
`;