export const bacteriaLevelInfo = [
  {
    name: 'actinobacteria',
    range: [40, 60],
    description: {
      high: 'Excess Actinobacteria on the skin might contribute to an overactive immune response, potentially leading to conditions such as rosacea, dermatitis, or excessive skin flaking. While they play a crucial role in skin health, an overabundance can disrupt microbial harmony.',
      low: 'Reduced levels of Actinobacteria may weaken the skin’s natural defense against harmful microbes, leading to increased susceptibility to infections and imbalanced oil production. This could result in drier, more sensitive skin with a compromised barrier function.'
    }
  },
  {
    name: 'firmicutes',
    range: [20, 30],
    description: {
      high: 'High levels of Firmicutes are associated with an increased risk of skin infections, as they may outcompete beneficial bacteria and contribute to inflammation. This imbalance could lead to conditions like acne, eczema, or other skin-related concerns, emphasizing the need for a balanced microbiome.',
      low: 'A deficiency in Firmicutes may result in an underactive skin defense system, reducing resilience against harmful bacteria and environmental stressors. This imbalance could contribute to skin dryness, irritation, or slower wound healing due to insufficient microbial diversity.'
    }
  },
  {
    name: 'bacteroidetes',
    range: [5, 10],
    description: {
      high: 'Elevated levels of Bacteroidetes might disrupt the skin’s microbiome equilibrium, potentially contributing to inflammatory conditions or excessive oil production. While their role is not entirely understood, an overabundance could interfere with the diversity necessary for healthy skin function.',
      low: 'Low levels of Bacteroidetes on the skin may indicate an imbalance in the skin microbiome. This could lead to a weakened skin barrier and reduced immune responses, making the skin more susceptible to environmental damage, irritation, and potential infections. However, the specific impact varies among individuals.'
    }
  },
  {
    name: 'proteobacteria',
    range: [5, 10],
    description: {
      high: 'An overabundance of Proteobacteria might lead to imbalances in the skin microbiome, potentially contributing to skin inflammation or altered sebum production. In some cases, excessive Proteobacteria have been linked to conditions such as acne or other inflammatory skin disorders.',
      low: 'Low Proteobacteria levels may indicate reduced microbial diversity, which can weaken the skin’s ability to protect itself from pathogens and environmental stressors. This could contribute to chronic skin irritation, increased sensitivity, or susceptibility to infections.'
    }
  }
];