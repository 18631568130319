import {Outlet} from "react-router-dom";
import {RegisterKitProvider} from "./RegisterKitContext";

const PatientSkinReportLayout = () => {

    return (
        <RegisterKitProvider>
            <Outlet/>
        </RegisterKitProvider>
    )
}

export default PatientSkinReportLayout;