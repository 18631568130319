export const formFields = [
  {
    name: "age",
    label: "age",
    type: "number",
    required: true,
  },
  {
    name: "sex",
    label: "sex",
    type: "select",
    required: true,
    options: [
      { value: "male", label: "male" },
      { value: "female", label: "female" },
    ],
  },
  {
    name: "city",
    label: "city",
    type: "text",
    required: true,
  },
  {
    name: "skincareConcerns",
    label: "skincare_concerns",
    type: "creatable-multi-select",
    required: true,
    options: [
      {value:"all",label:"all"},
      { value: "dryness", label: "dryness" },
      { value: "acne", label: "acne" },
      { value: "sensitivity", label: "sensitivity" },
      { value: "wrinkles", label: "wrinkles" },
      { value: "hyper-pigmentation", label: "hyper_pigmentation" },
      { value: "oiliness", label: "oiliness" },
      { value: "skin-peeling", label: "skin_peeling" },
    ],
  },
  {
    name: "acneFrequency",
    label: "acne_frequency",
    type: "select",
    required: true,
    options: [
      { value: "never", label: "never" },
      { value: "occasionally", label: "occasionally" },
      { value: "sometimes", label: "sometimes" },
      { value: "often", label: "often" },
      { value: "always", label: "always" },
    ],
  },
  {
    name: "waterIntake",
    label: "water_intake",
    type: "select",
    required: true,
    options: [
      { value: "less_than_1_liter", label: "less_than_1_liter" },
      { value: "1_to_2_liters", label: "1_to_2_liters" },
      { value: "2_to_3_liters", label: "2_to_3_liters" },
      { value: "more_than_3_liters", label: "more_than_3_liters" },
    ],
  },
  {
    name: "sleepOptions",
    label: "sleep_options",
    type: "select",
    required: true,
    options: [
      { value: "less_than_4_hours", label: "less_than_4_hours" },
      { value: "4_to_6_hours", label: "4_to_6_hours" },
      { value: "6_to_8_hours", label: "6_to_8_hours" },
      { value: "more_than_8_hours", label: "more_than_8_hours" },
    ],
  },
  {
    name: "stressLevel",
    label: "stress_level",
    type: "select",
    required: true,
    options: [
      { value: "low", label: "low" },
      { value: "moderate", label: "moderate" },
      { value: "high", label: "high" },
      { value: "very_high", label: "very_high" },
    ],
  },
  {
    name: "exerciseLevel",
    label: "exercise_level",
    type: "select",
    required: true,
    options: [
      { value: "never", label: "never" },
      { value: "1_2_times_a_week", label: "1_2_times_a_week" },
      { value: "3_4_times_a_week", label: "3_4_times_a_week" },
      { value: "more_than_4_times", label: "more_than_4_times" },
    ],
  },
  {
    name: "petOwernership",
    label: "pet_ownership",
    type: "creatable-multi-select",
    required: true,
    options: [
      { value: "no_pets", label: "no_pets" },
      { value: "cat", label: "cat" },
      { value: "dog", label: "dog" },
    ],
  },
  {
    name: "dietaryHabits",
    label: "dietary_habits",
    type: "multi-select",
    required: true,
    options: [
      { value: "dairy", label: "dairy" },
      { value: "alcohol", label: "alcohol" },
      { value: "processed_meat", label: "processed_meat" },
      { value: "veggies_and_fruits", label: "veggies_and_fruits" },
      { value: "dietary_supplements", label: "dietary_supplements" },
      { value: "refined_sugar", label: "refined_sugarr" },
      {
        value: "caffeine",
        label: "caffeine",
      },
    ],
  },
  {
    name: "smokeHabit",
    label: "smoke_habit",
    type: "select",
    required: true,
    options: [
      { value: "yes", label: "yes" },
      { value: "no", label: "no" },
    ],
  },
  {
    name: "screenTime",
    label: "screen_time",
    type: "select",
    required: true,
    options: [
      { value: "0_3_hours", label: "0_3_hours" },
      { value: "3_6_hours", label: "3_6_hours" },
      { value: "6_9_hours", label: "6_9_hours" },
      { value: "more_than_9_hours", label: "more_than_9_hours" },
    ],
  },
  {
    name: "sunligtExposure",
    label: "sunlight_exposure",
    type: "select",
    required: true,
    options: [
      { value: "less_than_1_hour", label: "less_than_1_hour" },
      { value: "1_to_3_hours", label: "1_to_3_hours" },
      { value: "3_to_6_hours", label: "3_to_6_hours" },
      { value: "more_than_6_hours", label: "more_than_6_hours" },
    ],
  },
  {
    name: "sunScreenUsage",
    label: "sun_screen_usage",
    type: "select",
    required: true,
    options: [
      { value: "never", label: "never" },
      { value: "beach_days", label: "beach_days" },
      { value: "summer_only", label: "summer_only" },
      { value: "sunny_days", label: "sunny_days" },
      { value: "always", label: "always" },
    ],
  },
  {
    name: "skincareProducts",
    label: "skincare_products",
    type: "creatable-multi-select",
    required: true,
    options: [
      { value: "cleanser", label: "cleanser" },
      { value: "moisturiser", label: "moisturiser" },
      { value: "serum", label: "serum" },
      { value: "toner", label: "toner" },
      { value: "eye_cream", label: "eye_cream" },
      { value: "spf", label: "spf" },
      { value: "acne_medication", label: "acne_medication" },
      { value: "retinol", label: "retinol" },
      { value: "nothing", label: "nothing" },
    ],
  },
  {
    name: "makeupFrequency",
    label: "makeup_frequency",
    type: "select",
    required: true,
    options: [
      { value: "never", label: "never" },
      { value: "less_than_3_times_a_week", label: "less_than_3_times_a_week" },
      { value: "3_to_5_times_a_week", label: "3_to_5_times_a_week" },
      { value: "every_day", label: "every_day" },
    ],
  },
];
