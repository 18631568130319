import React from 'react';
import TabContainer from './HorizontalTab.styles';

const HorizontalTab = ({tabs, currentTab,onTabSelect}) => {
    return (
        <TabContainer className="tab-buttons">

            {tabs.map((tab, index) => (
                <button
                    key={index}
                    onClick={() => onTabSelect(index)}
                    className={`${currentTab != index &&"!bg-transparent"}`}
                >
                    {tab}
                </button>
            ))}

        </TabContainer>
    );
}

export default HorizontalTab;
