import React, { useEffect, useState } from "react";
import {
  Form,
  LeftContainer,
  RightContainer,
  SideWrapper,
  TextContainer,
} from "../components/SideWrapper/SideWrapper.styles";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import LogoAndText from "../components/LogoAndText";
import { checkInvitationStatus } from "../utils/api-lists/verification.api";
import useFormData from "../hooks/useFormData";
import { register } from "../utils/api-lists/login.api";

const InvitationVerifyAndSignUpPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(
    new URLSearchParams(location.search).get("token") || ""
  );

  const [verification, setVerification] = useState(null);
  const [error, setError] = useState(null);

  const [status, setStatus] = useState({
    type: null,
    message: null,
  });
  const { t } = useTranslation("common");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    
    await register(
        formData.firstName,
        formData.lastName,
        verification.email,
        formData.password,
        formData.role,
        verification.organization_id,
        token
      );
      setStatus({
        type: "success",
        message: t("success_message"),
      });
      navigate("/login");
    } catch (err) {
      setStatus({
        type: "error",
        message: t("error_registration_failed"),
      });
    }
  };

  const [formData, setFormData] = useFormData({
    firstName: "",
    lastName: "",
    password: "",
    role:""
  });

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }

    checkInvitationStatus(token)
      .then((data) => {setVerification(data)
      })
      .catch((e) => setError(e));
  }, [token]);

  if (!verification) return;

  return (
    <SideWrapper>
      <LeftContainer>
        <LogoAndText />
      </LeftContainer>
      <RightContainer>
        <h1>You're Invited to! {verification.organization_name}</h1>
        <p>Email: {verification.email} </p>

        <TextContainer style={{ marginBottom: "40px" }}>
            <h2>{t("sign_up")}</h2>
          <h3>{t("create_an_account")}</h3>
        </TextContainer>
        <Form onSubmit={handleSubmit}>
          <label>{t("name")}</label>
          <input
            placeholder={t("name")}
            name="firstName"
            type="text"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("surname")}</label>
          <input
            placeholder={t("surname")}
            name="lastName"
            type="text"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("password")}</label>
          <input
            placeholder={t("password")}
            name="password"
            type="password"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("role")}</label>
          <select name="role" onChange={(e)=>setFormData(e)}>
            <option > {t("Select")}</option>
            <option value="COSMETOLOGIST"> {t("cosmetologist")}</option>
            <option value="DERMATOLOGIST"> {t("dermatologist")}</option>
            <option value="BEAUTY_CLINCS"> {t("beauty_clincs")}</option>
          </select>
          <p>{t("terms_and_conditions")}</p>
          <button type="submit" className="btn btn-4">
            {t("next")}
          </button>
          {status.type === "error" && (
            <p style={{ color: "red" }}>{status.message}</p>
          )}
          {status.type === "success" && (
            <p style={{ color: "green" }}>{status.message}</p>
          )}
        </Form>
        <p>
          {t("have_an_account")}{" "}
          <span onClick={() => navigate("/login")}>{t("sign_in")}</span>
        </p>
      </RightContainer>
    </SideWrapper>
  );
};

export default InvitationVerifyAndSignUpPage;
