import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    p {
        margin: 0 8px;
    }

    img {
        border-radius: 50%;
        max-width: none;
    }
`;

export const ProfileImg = styled.img`
    width: 50px;
    height: 50px;
    cursor: pointer;
   
`;