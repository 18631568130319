import React, {useCallback} from "react";
import Accordion from "../../widgets/accardion";
import {diversityIndex as diversityIndexSource} from "../../assets/data/diversity-index";
import {SmallBox} from "./widget/small-box";
import AgeComponent from "./widget/age.widge";
import SkinComponents from "./widget/skin-type.widget";
import DiversityIndexBar from "./widget/diversity-index-bar";
import TooltipWidget from "./widget/tooltip.widget";
import {ScatterChartWidget} from "./widget/scatter-chart.widget";
import {Accordion as NewAccardion, AccordionItem as Item} from '@szhsin/react-accordion';
import styled from "styled-components";
import api from "../../utils/api";
import {useParams} from "react-router-dom";
import {bacteriaLevelInfo} from "../../assets/data/bacteria-level-info";
import {skinTypeCombinations} from "../../assets/data/skin-type-combinations";

const Microbiome = ({report}) => {

  const fieldsToShow = [
    "actinobacteria",
    "firmicutes",
    "bacteroidetes",
    "proteobacteria",
  ]

  const bacteriaLevels = bacteriaLevelInfo;

  const {patientId} = useParams();
  const [reports, setReports] = React.useState([]);
  const [scattterDataLength, setScatterDataLength] = React.useState(0);
  const [scatterData, setScatterData] = React.useState({
    actinobacteria: [],
    firmicutes: [],
    bacteroidetes: [],
    proteobacteria: [],
  });

  const getDiversityIndex = (index) => {
    return diversityIndexSource.find(
      (item) => item.range[0] <= index && item.range[1] > index
    )?.description;
  };

  const fetchReports = useCallback(async () => {
    try {
      const response = await api.get(`/report/${patientId}`);

      if (response.status !== 200) {
        throw new Error('Failed to fetch reports');
      }

      const data = await response.data;
      setReports(data['reports']);
      setScatterData(mapReportDataToScatterData(data['reports']));

    } catch (error) {
    }
  }, [patientId]);

  const mapReportDataToScatterData = (reports) => {
    const scatterData = {};

    reports.forEach(report => {
      const date = report.created_at;
      const data = report.data;

      Object.keys(data).forEach(key => {
        if (fieldsToShow.includes(key)) {
          if (!scatterData[key]) {
            scatterData[key] = [];
          }
          scatterData[key].push({date, data: data[key]});
        }
      });
    });

    // Sort by date and take the last 3 entries
    Object.keys(scatterData).forEach(key => {
      scatterData[key].sort((a, b) => new Date(a.date) - new Date(b.date));
      scatterData[key] = scatterData[key].slice(-3);
    });

    if (reports.length >= 3) {
      setScatterDataLength(3);
    } else {
      setScatterDataLength(reports.length);
    }

    return scatterData;
  };

  React.useEffect(() => {
    if (patientId) {
      fetchReports();
    }
  }, [patientId, fetchReports]);

  const isInRangeColor = (value, name) => {
    const range = bacteriaLevels.find((b) => b.name === name).range;
    if (value >= range[1]) {
      return '#da6561';
    } else if (value < range[0]) {
      return '#da6561';
    } else {
      return '#55c575';
    }
  }

  const data = report?.data;
  const skinAge = data?.skin_age;
  const diversityIndex = data?.diversity_index;

  return (
    <div
      className="flex flex-wrap gap-3 m-auto vertical-layout"
      style={{maxWidth: "1300px"}}
    >

      <div className="flex gap-3 flex-1">
        <div className={'flex flex-col gap-3'}>
          <AgeComponent age={skinAge}
                        info={"Biological skin age expresses the condition of a  skin based on various multiple factors, not determined by your chronological age. We assess the health, vitality, and appearance of the skin, influenced by factors such as genetics, lifestyle choices, environmental exposure, and skincare applications. Additionally, we determine your biological skin age by calculating your microbiome diversity, skin hydration, wrinkles, pigmentation, tone, and collagen structure.\n"}/>
          <SkinComponents skin_type={data?.skin_index}
                          info={skinTypeCombinations.find((type) => type.name === data?.skin_index)?.description}/>
        </div>
        <div>
          <Accordion
            closable={false}
            className="flex flex-col gap-3 flex-1"
            title={"Microbiome Diversity Index"}
            rightAction={<TooltipWidget
              title={"Microbiome Diversity Index"}
              message={"The presence and proportions of different bacteria are among the\n" +
                "                                most important indicators of skin health. An excessive abundance\n" +
                "                                of any bacteria is considered an early sign of disease. We\n" +
                "                                calculate your biodiversity index by measuring the amount of\n" +
                "                                bacterial genetic material present in the samples taken from\n" +
                "                                your face."}/>}
            children={
              <div className="flex flex-col gap-3 mt-3">
                {diversityIndex && (
                  <DiversityIndexBar percentage={diversityIndex * 100}/>

                )}
                <p>{getDiversityIndex(diversityIndex)}</p>
                {/*{report["data"] && (*/}
                {/*    <BarChartTable myGraphData={data}></BarChartTable>*/}
                {/*)}*/}
              </div>
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3 flex-1">
        <Accordion
          closable={false}
          title={"Your Microbiome"}
          rightAction={<TooltipWidget
            title={"Your Microbiome"}
            message={"Microbiome data is isolated on the same day from the samples taken from you at our laboratory. The obtained microbial DNA is frozen and sent to our partner laboratories in Munich, Germany for next-generation sequencing. After obtaining your personal microbiome sequencing data, the results are delivered to our headquarters in Luxembourg. Here, all your data is analyzed with Glacies technology, and your personalized facial profile is created.\n"}/>
          }
          children={
            <div className="flex flex-col gap-3">
              <NewAccardion>
                <AccordionItem header="Actinobacteria" initialEntered
                               value={scatterData['actinobacteria'][scattterDataLength - 1]?.data}
                               defineColor={isInRangeColor(scatterData['actinobacteria'][scattterDataLength - 1]?.data, 'actinobacteria')}>
                  <ScatterChartWidget nums={scatterData['actinobacteria']} name={'actinobacteria'} min={20} max={80}/>
                </AccordionItem>

                <AccordionItem header="Firmicutes"
                               value={scatterData['firmicutes'][scattterDataLength - 1]?.data}
                               defineColor={isInRangeColor(scatterData['firmicutes'][scattterDataLength - 1]?.data, 'firmicutes')}
                >
                  <ScatterChartWidget nums={scatterData['firmicutes']} name={'firmicutes'} min={10} max={40}/>
                </AccordionItem>

                <AccordionItem header="Bacteroidetes"
                               value={scatterData['bacteroidetes'][scattterDataLength - 1]?.data}
                               defineColor={isInRangeColor(scatterData['bacteroidetes'][scattterDataLength - 1]?.data, 'bacteroidetes')}
                >
                  <ScatterChartWidget nums={scatterData['bacteroidetes']} name={'bacteroidetes'} min={0} max={15}/>
                </AccordionItem>

                <AccordionItem header="Proteobacteria"
                               value={scatterData['proteobacteria'][scattterDataLength - 1]?.data}
                               defineColor={isInRangeColor(scatterData['proteobacteria'][scattterDataLength - 1]?.data, 'proteobacteria')}
                >
                  <ScatterChartWidget nums={scatterData['proteobacteria']} name={'proteobacteria'} min={0} max={15}/>
                </AccordionItem>
              </NewAccardion>
              {/*<SkinReportStraightChart chartData={data}/>*/}
              {/*<SkinReportStraightChart isDefault={true}/>*/}
              {/*<p>*/}
              {/*    Microbiome data is isolated on the same day from the samples*/}
              {/*    taken from you at our laboratory. The obtained microbial DNA is*/}
              {/*    frozen and sent to our partner laboratories in Munich, Germany*/}
              {/*    for next-generation sequencing. After obtaining your personal*/}
              {/*    microbiome sequencing data, the results are delivered to our*/}
              {/*    headquarters in Luxembourg. Here, all your data is analyzed with*/}
              {/*    Glacies technology, and your personalized facial profile is*/}
              {/*    created.*/}
              {/*</p>*/}
            </div>
          }
        />
      </div>
    </div>
  );
};


const ItemWithChevron = ({header, value, defineColor, ...rest}) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <div className="chevron-down">
          <div className={"circle "} style={{backgroundColor: defineColor}}></div>
          <p>{value}%</p>
        </div>
      </>
    }
  />
);


const AccordionItem = styled(ItemWithChevron)`
    border-bottom: 1px solid #ccc;

    .szh-accordion__item {
        &-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 1rem;
            font-size: 1rem;
            font-weight: 400;
            text-align: left;
            color: #007bff;
            background-color: transparent;
            border: none;

            &:hover {
                background-color: #f3f3f3;
            }
        }

        &-content {
            transition: height 0.25s cubic-bezier(0, 0, 0, 1);
        }

        &-panel {
            padding: 1rem;
        }
    }

    .chevron-down {
        margin-left: auto;
        display: flex;
        gap: 3px;
        align-items: center;

        p {
            font-size: 1rem;
            font-weight: normal;
        }
    }

    .circle {
        border-radius: 50%;
        width: 12px;
        height: 12px;
    }

    &.szh-accordion__item--expanded {
        .szh-accordion__item-btn {
            background-color: #e7e7e7;
        }

        .chevron-down {
        }
    }
`;


export default Microbiome;
