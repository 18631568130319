import React, {useCallback, useEffect, useRef, useState} from 'react';
import Webcam from 'react-webcam';
import {CanvasWrapper} from "./FaceDetection.style";

const API_URL = process.env.REACT_APP_WEB_SOCKET_URL || 'ws://localhost:8765';

const FaceDetection = ({
                         webcamRef, mirror = true, facingMode = "user", hide = false, onStatusChange = () => {
  }
                       }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [statusMessages, setStatusMessages] = useState([{text: 'Waiting for webcam access...', type: 'normal'}]);
  const [visualFeedback, setVisualFeedback] = useState(null);
  const [feedbackStatus, setFeedbackStatus] = useState('neutral'); // 'neutral', 'error', 'warning', 'success'

  const canvasRef = useRef(null);
  const hiddenCanvasRef = useRef(null); // For capturing clean image without drawings
  const socketRef = useRef(null);
  const videoContainerRef = useRef(null);
  const animationFrameRef = useRef(null);
  const lastSendTimeRef = useRef(0); // To track when the last WebSocket message was sent

  const connectWebSocket = useCallback(() => {
    console.log("WEBSOCKET", API_URL);
    const socket = new WebSocket(API_URL);

    socket.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
    };

    socket.onmessage = (event) => {
      const results = JSON.parse(event.data);
      // console.log('Results:', results);

      // Extract visual feedback if available
      if (results.visual_feedback) {
        setVisualFeedback(results.visual_feedback);
      } else {
        setVisualFeedback(null);
      }

      updateUI(results);
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
      setIsConnected(false);

      setTimeout(connectWebSocket, 2000);
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      socket.close();
    };

    socketRef.current = socket;
  }, []);

  const updateUI = useCallback((results) => {
    let messages = [];
    let noFace = false;
    let newStatus = 'success';

    if (results.face_count > 0) {
      if (results.face_count === 1) {
        messages.push({text: 'One face detected', type: 'success'});
      } else {
        messages.push({text: `${results.face_count} faces detected`, type: 'error'});
        newStatus = 'error';
      }
      noFace = false;
    } else {
      messages.push({text: 'No Face detected', type: 'error'});
      noFace = true;
      newStatus = 'error';
    }

    if (!noFace) {
      if (results.alignment_issue) {
        messages.push({text: results.alignment_issue, type: 'error'});
        newStatus = 'error';
      } else {
        messages.push({text: 'Face aligned correctly', type: 'success'});
      }

      if (results.distance_issue) {
        messages.push({text: results.distance_issue, type: 'error'});
        newStatus = 'error';
      } else {
        messages.push({text: 'Face at correct distance', type: 'success'});
      }

      if (results.lighting_issues && results.lighting_issues.length > 0) {
        messages.push({text: 'Lighting issues:', type: 'warning'});
        results.lighting_issues.forEach(issue => {
          if (issue) {
            messages.push({text: `- ${issue}`, type: 'warning'});
          }
        });
        // If only lighting issues but no other problems, set status to warning
        if (newStatus === 'success') {
          newStatus = 'warning';
        }
      } else {
        messages.push({text: 'Lighting conditions good', type: 'success'});
      }
    }

    setStatusMessages(messages);
    setFeedbackStatus(newStatus);

    onStatusChange({
      faceDetected: results.face_count > 0,
      inPosition: !results.alignment_issue && !results.distance_issue,
      goodLighting: !results.lighting_issues || results.lighting_issues.length === 0,
      statusMessages: messages
    });
  }, [onStatusChange]);

  const drawGuide = useCallback(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear the canvas before redrawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw webcam video first to establish background
    if (webcamRef.current && webcamRef.current.video) {
      ctx.drawImage(webcamRef.current.video, 0, 0, canvas.width, canvas.height);
    }

    const width = canvas.width;
    const height = canvas.height;

    // Apply a semi-transparent overlay to the entire canvas
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
    ctx.fillRect(0, 0, width, height);

    // Draw oval guide
    const centerX = width / 2;
    const centerY = height / 2;
    let radiusX, radiusY;

    if (height > width) {
      radiusX = (height / 3) - 50;
      radiusY = (width / 2) - 20;
    } else {
      radiusX = (width / 3) - 65;
      radiusY = (height / 2) - 10;
    }

    // Create oval clipping path
    ctx.save();
    ctx.beginPath();
    ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
    ctx.clip();

    // Clear the oval area to show the original image
    if (webcamRef.current && webcamRef.current.video) {
      ctx.drawImage(webcamRef.current.video, 0, 0, canvas.width, canvas.height);
    }
    ctx.restore();

    // Draw oval outline with color based on status
    ctx.beginPath();
    ctx.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);

    // Set color based on feedback status
    if (feedbackStatus === 'error') {
      ctx.strokeStyle = 'red';
    } else if (feedbackStatus === 'warning') {
      ctx.strokeStyle = 'yellow';
    } else if (feedbackStatus === 'success') {
      ctx.strokeStyle = 'green';
    } else {
      ctx.strokeStyle = 'white';
    }

    ctx.lineWidth = 3;
    ctx.stroke();

  }, [webcamRef, feedbackStatus]);

  const captureCleanFrame = useCallback(() => {
    if (!webcamRef.current || !hiddenCanvasRef.current) return null;

    const video = webcamRef.current.video;
    const canvas = hiddenCanvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions to match video
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the video frame to canvas
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Compress the image by reducing resolution and quality
    const scaleFactor = 0.75; // Reduce resolution to 75%
    const scaledWidth = canvas.width * scaleFactor;
    const scaledHeight = canvas.height * scaleFactor;

    // Create a temporary canvas for resizing
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = scaledWidth;
    tempCanvas.height = scaledHeight;
    const tempCtx = tempCanvas.getContext('2d');

    // Draw the original image onto the smaller canvas
    tempCtx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight);

    // Return the resized image as data URL with reduced quality
    return tempCanvas.toDataURL('image/jpeg', 0.7);
  }, [webcamRef]);

  const captureFrame = useCallback(() => {
    if (!isCapturing || !canvasRef.current || !webcamRef.current) return;

    // Draw the visual feedback
    drawGuide();

    // Throttle WebSocket requests to once per 200ms
    const currentTime = Date.now();
    if (isConnected && socketRef.current && (currentTime - lastSendTimeRef.current >= 200)) {
      // Get clean image without any drawings
      const imageData = captureCleanFrame();
      if (imageData) {
        socketRef.current.send(JSON.stringify({image: imageData}));
        lastSendTimeRef.current = currentTime;
      }
    }

    animationFrameRef.current = requestAnimationFrame(captureFrame);
  }, [isCapturing, isConnected, webcamRef, drawGuide, captureCleanFrame]);

  const setupCanvas = useCallback(() => {
    if (!webcamRef.current || !canvasRef.current) return;

    const video = webcamRef.current.video;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Setup hidden canvas for capturing clean images
    if (!hiddenCanvasRef.current) {
      const hiddenCanvas = document.createElement('canvas');
      hiddenCanvas.style.display = 'none';
      document.body.appendChild(hiddenCanvas);
      hiddenCanvasRef.current = hiddenCanvas;
    }

    drawGuide();
  }, [webcamRef, drawGuide]);

  const onUserMedia = useCallback(() => {
    if (!webcamRef.current || hide) return;
    console.log("Webcam ready");

    const video = webcamRef.current.video;
    if (!video) return;

    if (!videoContainerRef.current) {
      const container = document.getElementById('video-container');
      if (container) {
        videoContainerRef.current = container;
      } else {
        console.error('Video container element not found');
        return;
      }
    }

    if (!canvasRef.current && videoContainerRef.current) {
      try {
        const canvas = document.createElement('canvas');
        canvas.style.position = 'absolute';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.width = '100%';
        canvas.style.height = 'auto';
        canvas.style.zIndex = '1';
        // const canvas = document.createElement('canvas');
        canvas.style.position = 'relative';
        canvas.style.width = '100%';
        canvas.style.height = 'auto';
        canvas.style.zIndex = '1';

        videoContainerRef.current.appendChild(canvas);
        canvasRef.current = canvas;

        if (video.readyState >= 2) {
          setupCanvas();
        } else {
          video.addEventListener('loadedmetadata', setupCanvas);
        }

        connectWebSocket();

        setIsCapturing(true);
      } catch (err) {
        console.error('Error creating canvas:', err);
        return;
      }
    }
  }, [webcamRef, hide, setupCanvas, connectWebSocket]);

  useEffect(() => {
    if (isCapturing) {
      animationFrameRef.current = requestAnimationFrame(captureFrame);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isCapturing, captureFrame]);

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      if (canvasRef.current && videoContainerRef.current) {
        try {
          videoContainerRef.current.removeChild(canvasRef.current);
        } catch (err) {
          console.error('Error removing canvas:', err);
        }
      }

      if (hiddenCanvasRef.current) {
        try {
          document.body.removeChild(hiddenCanvasRef.current);
        } catch (err) {
          console.error('Error removing hidden canvas:', err);
        }
      }

      setIsCapturing(false);
      setIsConnected(false);
    };
  }, []);

  useEffect(() => {
    if (hide) {
      if (isCapturing) {
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
        }
        setIsCapturing(false);
      }
    } else {
      if (!isCapturing) {
        setIsCapturing(true);
        animationFrameRef.current = requestAnimationFrame(captureFrame);
      }
    }
  }, [hide, isCapturing, captureFrame]);

  const onAbort = useCallback(() => {
    console.log("Webcam aborted");

    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    if (canvasRef.current && videoContainerRef.current) {
      try {
        videoContainerRef.current.removeChild(canvasRef.current);
      } catch (err) {
        console.error('Error removing canvas:', err);
      }
      canvasRef.current = null;
    }

    if (hiddenCanvasRef.current) {
      try {
        document.body.removeChild(hiddenCanvasRef.current);
      } catch (err) {
        console.error('Error removing hidden canvas:', err);
      }
      hiddenCanvasRef.current = null;
    }

    setIsCapturing(false);
  }, []);

  return (
    <CanvasWrapper>
      <div
        id="video-container"
        style={{position: 'relative', width: '100%', height: 'auto', display: hide ? 'none' : 'block'}}
        className={mirror ? 'mirrorCanvas' : ''}
      >
        {!hide && (
          // Hide the webcam element as we'll draw everything on canvas
          <Webcam
            disabled={hide}
            ref={webcamRef}
            audio={false}
            className="webcam"
            mirrored={mirror}
            style={{
              width: '100%',
              height: 'auto',
              border: '1px solid #ccc',
              opacity: 0, // Hide the original video
              position: 'absolute',
            }}
            videoConstraints={{
              facingMode
            }}
            onUserMedia={onUserMedia}
            onAbort={onAbort}
          />
        )}
      </div>
      {statusMessages.length > 0 && !hide && (
        <div
          id="status"
          className="statusMessages"
          style={{
            height: 130,
            minWidth: '300px',
            padding: '15px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            marginTop: '20px'
          }}
        >
          {statusMessages.map((msg, index) => (
            <p
              key={index}
              style={{
                color: msg.type === 'success' ? 'green' :
                  msg.type === 'error' ? 'red' :
                    msg.type === 'warning' ? 'orange' : 'black',
                margin: '2px 0'
              }}
            >
              {msg.text}
            </p>
          ))}
        </div>
      )}
    </CanvasWrapper>
  );
};

export default FaceDetection;