import React from 'react';
import { Button } from './Buttons.styles';

export const IconButton = ({icon:Icon,text,onClick}) => {
    return (
        <Button onClick={onClick}>
            <Icon/>
            {text}
        </Button>
    );
}

