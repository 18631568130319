import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRole } from "../context/RoleContext";
import { register } from "../utils/api-lists/login.api";

// Components
import { useTranslation } from "react-i18next";
import LogoAndText from "../components/LogoAndText";
import {
  Form,
  LeftContainer,
  RightContainer,
  SideWrapper,
  TextContainer,
} from "../components/SideWrapper/SideWrapper.styles";
import useFormData from "../hooks/useFormData";

const Signup2Page = () => {
  const [formData, setFormData] = useFormData({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [status, setStatus] = useState({
    type: null,
    message: null,
  });

  const navigate = useNavigate();
  const { role } = useParams();
  const decodedRole = decodeURIComponent(role);
  const { roleCheck } = useRole();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (!roleCheck) {
      navigate("/sign-up"); // Redirect to the first page if the role is not set
    }
  }, [roleCheck, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(
        formData.firstName,
        formData.lastName,
        formData.email,
        formData.password,
        decodedRole,
      );
      setStatus({
        type: "success",
        message: t("success_message"),
      });
      navigate("/almost-there");
    } catch (err) {
      setStatus({
        type: "error",
        message: t("error_registration_failed"),
      });
    }
  };

  return (
    <SideWrapper>
      <LeftContainer>
        <LogoAndText />
      </LeftContainer>
      <RightContainer>
        <h2>{t("sign_up")}</h2>
        <TextContainer style={{ marginBottom: "40px" }}>
          <h3>{t("create_an_account")}</h3>
        </TextContainer>
        <Form onSubmit={handleSubmit}>
          <label>{t("name")}</label>
          <input
            placeholder={t("name")}
            name="firstName"
            type="text"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("surname")}</label>
          <input
            placeholder={t("surname")}
            name="lastName"
            type="text"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("email")}</label>
          <input
            placeholder={t("email")}
            name="email"
            type="email"
            onChange={(e) => setFormData(e)}
            required
          />
          <label>{t("password")}</label>
          <input
            placeholder={t("password")}
            name="password"
            type="password"
            onChange={(e) => setFormData(e)}
            required
          />
          <p>{t("terms_and_conditions")}</p>
          <button type="submit" className="btn btn-4">
            {t("next")}
          </button>
          {status.type === "error" && (
            <p style={{ color: "red" }}>{status.message}</p>
          )}
          {status.type === "success" && (
            <p style={{ color: "green" }}>{status.message}</p>
          )}
        </Form>
        <p>
          {t("have_an_account")}{" "}
          <span onClick={() => navigate("/login")}>{t("sign_in")}</span>
        </p>
      </RightContainer>
    </SideWrapper>
  );
};

export default Signup2Page;
