import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {PatientContainer, PatientListContainer, PatientPhoto, Wrapper} from "./PatientList.styles";
import {getAllPatients} from '../../utils/api-lists/patient.api';
import Photo from "../../assets/profile-img.svg";
import {UserContext} from "../../context/UserContext";
import {DivRow} from "../../widgets/GeneralBody";
import {FaPlus} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import Alert from "../Alert";

const PatientList = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const {message, type} = location.state || {message:null,type:null};
    const {user} = useContext(UserContext);

    const [patients, setPatients] = useState([]);
    const [error, setError] = useState(null);
   
    const {t} = useTranslation('common');

    useEffect(() => {
        if (!user?.id) return;

        const fetchPatients = async () => {
            try {
                const data = await getAllPatients(user.id);
                setPatients(data);
            } catch (err) {
                setError(err);
            }
        };

        fetchPatients();
    }, [user?.id]);

    return (
        <Wrapper>
            {error && <Alert message={error} type='error'/>}
            {message&&type&&<Alert message={message} type={type}/>}
            <DivRow className="header-patient-list">
                <p>{t('patient_details')}</p>
                <FaPlus size={18} onClick={() => navigate('/new-patient')} className="clickable"/>
            </DivRow>
            <PatientListContainer>
                {patients.slice().reverse().map((patient) => (
                    <PatientContainer key={patient.id} onClick={() => navigate(`/patient-cabinet/${patient.id}`)}>
                        <p>{patient.first_name} {patient.last_name}</p>
                        <PatientPhoto src={Photo} alt="Patient"/>
                    </PatientContainer>
                ))}
            </PatientListContainer>
            {/*<button onClick={() => navigate('/new-patient')} type="submit" className="btn btn-2 btn-align-right">Add New*/}
            {/*    Patient*/}
            {/*</button>*/}
        </Wrapper>
    )
};

export default PatientList;
