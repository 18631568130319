import { Tag } from "antd";
import { IoSunny } from "react-icons/io5";
import { MdOutlineNightlight } from "react-icons/md";
import styled from "styled-components";

export const StyledTag = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: ${(props) => (props.$fullWidth ? "100%" : "120px")};
  background-color: ${(props) => (props.$isMorning ? "#F5C75D" : "#283158")};
  border: none;
`;


export const IconImage = styled.img`
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(1);
`;

export const TagText = styled.span`
color: #fff !important;
font-weight: bold;
line-height: 14px;
letter-spacing: 0.5px;
`;

export const StyledSunIcon = styled(IoSunny)`
  font-size: 22px;
  fill:white;
`;

export const StyledMoonIcon = styled(MdOutlineNightlight)`
  fill: white;
  font-size: 22px;
  transform: rotate(-30deg);
`;