import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import HorizontalTab from "../../components/HorizontalTab";
import api from "../../utils/api";
import { GeneralBody } from "../../widgets/GeneralBody";
import "../PatientCabinet/skin-reports/skin-report.css";
import "./detail.css";
import FaqTab from "./FaqTab";
import Microbiome from "./Microbiome";
import PersonalGrowTab from "./PersonalGrow";
import SkinProfileTab from "./SkinProfileTab";
import { FullScreeen } from "./widget/sharedPage.styles";

ChartJS.register(ArcElement, Tooltip, Legend);

const PatientSkinReportDetailSharePageV2 = () => {
  const {reportId} = useParams();
  const [report, setReport] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const {t} = useTranslation("common");


  const getTabContent = (tab, report) => {
    switch (tab) {
      default:
      case 0:
        return <Microbiome report={report}/>;
      case 1:
        return <SkinProfileTab report={report} />;
      case 2:
        return <PersonalGrowTab report={report} products={products}/>;
      case 3:
        return <FaqTab report={report}/>;
    }
  };

  function saveAsPdf() {
    html2canvas(document.getElementById("myPage"), {
      quality: 0.95,
      backgroundColor: "#2c1d37",
      foreignObjectRendering: false,
      windowWidth: 1200,
      width: 1200,

      removeContainer: true,
    }).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      const pdf = new jsPDF("p", "px", [
        dataUrl.width + 100,
        dataUrl.height + 100,
      ]);
      pdf.setFillColor("#2c1d37");
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      console.log(imgProps.width, imgProps.height);
      pdf.addImage(dataUrl, "PNG", 50, 50, 1200 * 2, imgProps.height);
      pdf.save("download.pdf");
    });
  }

  useEffect(() => {
    api.get(`/products`).then((response) => {
      setProducts(response.data["products"]);
    });
    api.get(`/report_by_id/${reportId}`).then((data) => {
      console.log("data", data);
      setReport(data.data["report"]);
      console.log("report", report);
    });
  }, [reportId]);

  return (
    <FullScreeen>
      <div className="flex flex-col items-center">
        <div className="mb-4">
          <p className="text-2xl font-bold leading-[30px]">
            Precise Skincare Report
          </p>
        </div>
        <div className="mb-2">
          <p className="text-lg leading-[30px]">
            Exclusively for{" "}
            <em className="font-semibold">{report?.patient_full_name}</em>
          </p>
        </div>
        <p className="text-lg leading-[30px]">
          By <strong className="font-semibold">Glacies Biome</strong>
        </p>
      </div>

      {report && report["data"] ? (
        <GeneralBody id="myPage">
          <TransitionGroup className="content-wrapper">
            <CSSTransition key={currentTab} classNames="content" timeout={500}>
              {getTabContent(currentTab, report)}
            </CSSTransition>
          </TransitionGroup>

          <div style={{height: 120}}></div>
          <HorizontalTab
            tabs={[t("microbiome"), t("skin_profile"), t("your_routine"), t("faq")]}
            currentTab={currentTab}
            onTabSelect={setCurrentTab}
          />

        </GeneralBody>
      ) : (
        <ClipLoader
          color={"#ffffff"}
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </FullScreeen>
  );
};


export default PatientSkinReportDetailSharePageV2;
