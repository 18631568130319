import styled from "styled-components";

const ProductStepWrapper = styled.div`
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: 80px;
  padding-top: 2px;
  padding-bottom: 2px;
  overflow: hidden;
  cursor:pointer;

  background-color: ${(props) => (props.isActive ? "#d3d3d3" : "white")};  // Dark gray when active
  transition: background-color 0.3s ease-in-out;
`;

const ProductInfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;

  p {
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  img {
    width: 50px;
    height: 70px;
    object-fit: contain; 
    object-position: center;
  }
`;

const StepWrapper = styled.div`
  margin-left: auto;
  margin-right: 10px;

  p {
    font-size: 18px !important;
    font-weight: 700 !important;
  }
`;

export { ProductStepWrapper, ProductInfoWrapper, StepWrapper };