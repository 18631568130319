import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DashboardWrapper } from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import { Button } from "../../../components/PatientProfile/PatientProfile.styles";
import { getSurveysByPatientId } from "../../../utils/api-lists/patient.api";
import { GeneralBody } from "../../../widgets/GeneralBody";
import { ListItemDiv } from "../../../widgets/ListItem/ListItem.style";
import { Header } from "../styles";
import Alert from "../../../components/Alert";
import { UserContext } from "../../../context/UserContext";
import { MdContentCopy } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import { IconButton } from "../../../components/Buttons";
import { copyUrlToClipboard } from "../../../utils/general.util";

const PatientSurveys = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { message, type } = location.state || { message: null, type: null };
  const { patientId } = useParams();
  const { t } = useTranslation("common");

  const [surveys, setSurveys] = useState([]);
  const [alert, setAlert] = useState({ message: "", type: "" });

  useEffect(() => {
    const fetchSurveys = async () => {
      try {
        const data = await getSurveysByPatientId(patientId);
        setSurveys(data);
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };

    fetchSurveys();
  }, [patientId]);

  useEffect(() => {
    if (message && type) {
      setAlert({ message, type });

      navigate("", { replace: true });
    }
  }, [message, type, navigate]);

  const handleAlertClose = () => {
    setAlert({ message: "", type: "" });
  };

  const handleCopyLink = () => {
    const path = `/create-survey?patientId=${patientId}&doctorId=${user.id}`;

    copyUrlToClipboard(
      path,
      () => {
        setAlert({
          message: t("link_copied"),
          type: "success",
        });
      },
      () => {
        setAlert({
          message: t("link_copy_error"),
          type: "error",
        });
      }
    );
  };

  return (
    <DashboardWrapper>
      <Header>
        <IconContext.Provider value={{ style: { cursor: "pointer" } }}>
          <FaArrowLeft
            onClick={() => navigate(-1)}
            size={16}
            style={{ minWidth: 16, height: 16 }}
          />
        </IconContext.Provider>
        <h3>{t("lifeStyle")}</h3>

        <IconButton
          icon={MdContentCopy}
          text={t("copy_generated_link")}
          onClick={handleCopyLink}
        />

        <IconButton
          icon={IoCreateOutline}
          text={t("new_lifeStyle_survey")}
          onClick={() => {
            navigate(`/patient-cabinet/${patientId}/patient-create-survey`);
          }}
        />
      </Header>
      <GeneralBody>
        {alert.message && alert.type && (
          <Alert
            message={alert.message}
            type={alert.type}
            onClose={handleAlertClose}
          />
        )}
        {surveys.map((survey, index) => {
          return (
            <ListItemDiv
              className={"overflow-hidden"}
              onClick={() => {
                navigate(
                  `/patient-cabinet/${patientId}/patient-survey/${survey.id}`
                );
              }}
              key={index}
            >
              <p className="mr-7">
                {t("survey")}: {++index}
              </p>
              <h4 className={"truncate"}>{survey.id}</h4>
              <p className="text-right">
                {format(survey.timestamp, "MM-dd-yyyy HH:mma")}
              </p>
            </ListItemDiv>
          );
        })}
      </GeneralBody>
    </DashboardWrapper>
  );
};

export default PatientSurveys;
