import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {GlobalStyle} from './GlobalStyle';
import GuestRoute from './utils/GuestRoute';
import ProtectedRoute from './utils/ProtectedRoute';

//Pages
import {CookieConsent} from "react-cookie-consent";
import {Toaster} from "react-hot-toast";
import Layout from "./components/Layout";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import DoctorProfilePage from './pages/DoctorProfilePage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import FaceDetectionPage from './pages/FaceDetectionPage';
import ForgotPasswordPage from './pages/ForgetPasswordPage';
import HelpPage from './pages/HelpPage';
import HomePage from './pages/HomePage';
import JoinUsPage from './pages/JoinUsPage';
import LoginPage from './pages/LoginPage';
import NewPatientPage from './pages/NewPatientPage';
import NewTestPage from './pages/NewTestPage';
import CompareFilesPage from "./pages/PatientCabinet/FileManagement/CompareFiles.page";
import FileUploadPage from "./pages/PatientCabinet/FileManagement/FileUploadPage";
import VisualAnalysis from "./pages/PatientCabinet/FileManagement/VisualAnalysis";
import PatientsCabinetPage from './pages/PatientCabinet/PatientsCabinetPage';
import PatientsReportRequest from './pages/PatientCabinet/PatientsReportRequest';
import PdfPage from './pages/PatientCabinet/PdfPage';
import PdfView from "./pages/PatientCabinet/PdfView";
import RegisterKitPage from "./pages/PatientCabinet/register-kit/register-kit.page";
import RegisterKitLayout from "./pages/PatientCabinet/register-kit/RegisterKitLayout";
import PatientSkinReportDetailPage from "./pages/PatientCabinet/skin-reports/PatientSkinReportDetail";
import PatientSkinReportDetailPageV2 from "./pages/PatientCabinet/skin-reports/PatientSkinReportDetailV2";
import PatientSkinReportLayout from "./pages/PatientCabinet/skin-reports/PatientSkinReportLayout";
import PatientSkinReportPage from "./pages/PatientCabinet/skin-reports/PatientSkinReportPage";
import PatientSurveyDetails from './pages/PatientCabinet/survey/PatientSurveyDetails';
import PatientSurveys from './pages/PatientCabinet/survey/PatientSurveys';
import SubmitPatientSurvey from './pages/PatientCabinet/survey/SubmitPatientSurvey';
import PatientListPage from './pages/PatientListPage';
import PostSignupPage from './pages/PostSignupPage';
import SelectPatientPage from './pages/SelectPatientPage';
import Signup1Page from './pages/Signup1Page';
import Signup2Page from './pages/Signup2Page';
import PatientSkinReportDetailSharePage from "./pages/standalone/PatientSkinReportDetailShare";
import PatientSkinReportDetailSharePageV2 from "./pages/standalone/PatientSkinReportDetailSharev2";
import SuccessSentForgotPassword from "./pages/SuccessSentForgotPassword";
import PatientSurveySubmitShare from './pages/standalone/PatientSurveySubmitShare';
import {ConfigProvider, theme} from "antd";
import InvitationVerifyAndSignUpPage from './pages/InvitationVerifyAndSignUpPage';

function App() {
  return (
    <Router>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        <GlobalStyle/>
        <CookieConsent
          location="bottom"
          cookieName="GlaciesCookies"
          style={{background: "#2B373B"}}
          buttonStyle={{color: "#4e503b", fontSize: "13px"}}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <Layout>
          <Routes>
            <Route path='/' element={<GuestRoute> <LoginPage/> </GuestRoute>}/>
            <Route path='/login' element={<GuestRoute> <LoginPage/> </GuestRoute>}/>
            <Route path='/forgot-password' element={<GuestRoute> <ForgotPasswordPage/> </GuestRoute>}/>
            <Route path='/success' element={<GuestRoute> <SuccessSentForgotPassword/> </GuestRoute>}/>
            <Route path='/almost-there' element={<GuestRoute> <PostSignupPage/> </GuestRoute>}/>
            <Route path='/verify' element={<GuestRoute> <InvitationVerifyAndSignUpPage/> </GuestRoute>}/>
            <Route path='/sign-up-next/:role' element={<GuestRoute> <Signup2Page/> </GuestRoute>}/>
            <Route path='/share/v1/:reportId'
                   element={<GuestRoute> <PatientSkinReportDetailSharePage/> </GuestRoute>}/>
            <Route path='/share/:reportId'
                   element={<GuestRoute> <PatientSkinReportDetailSharePageV2/> </GuestRoute>}/>
            <Route path='/create-survey'
                   element={<GuestRoute> <PatientSurveySubmitShare/> </GuestRoute>}/>
            <Route path="/face-detection" element={<FaceDetectionPage/>}/>
            <Route path='/dashboard' element={<ProtectedRoute> <HomePage/> </ProtectedRoute>}/>
            <Route path='/help-page' element={<ProtectedRoute> <HelpPage/> </ProtectedRoute>}/>
            <Route path='/new-patient' element={<ProtectedRoute> <NewPatientPage/> </ProtectedRoute>}/>
            <Route path='/patient-list' element={<ProtectedRoute> <PatientListPage/> </ProtectedRoute>}/>
            <Route path='patient-cabinet/:patientId' element={<ProtectedRoute/>}>
              <Route path='' element={<PatientsCabinetPage/>}/>
              <Route path='skin-report' element={<PatientSkinReportLayout/>}>
                <Route path='' element={<PatientSkinReportPage/>}/>
                <Route path=':reportId' element={<PatientSkinReportDetailPageV2/>}/>
                <Route path=':reportId/v2' element={<PatientSkinReportDetailPage/>}/>
              </Route>
              <Route path='register-kit' element={<RegisterKitLayout/>}>
                <Route path='' element={<RegisterKitPage/>}/>
                <Route path=':kitId' element={<h1>Detail</h1>}/>
              </Route>
              <Route path='request-new-report' element={<PatientsReportRequest/>}/>
              <Route path='skin-report-files'>
                <Route path='' element={<PdfPage/>}/>
                <Route path=':pdfid' element={<PdfView/>}/>
              </Route>
              <Route path='analysis'>
                <Route path='' element={<VisualAnalysis/>}/>
                <Route path='file-upload' element={<FileUploadPage/>}/>
                <Route path='compare' element={<CompareFilesPage/>}/>
                <Route path='wrinkles' element={<CompareFilesPage/>}/>
              </Route>
              <Route path='patient-survey' element={<PatientSurveys/>}/>
              <Route path='patient-survey/:surveyId' element={<PatientSurveyDetails/>}/>
              <Route path='patient-create-survey' element={<SubmitPatientSurvey/>}/>
            </Route>
            <Route path='/new-test' element={<ProtectedRoute> <NewTestPage/> </ProtectedRoute>}/>
            <Route path='/pdf/:patientId' element={<ProtectedRoute> <PdfPage/> </ProtectedRoute>}/>
            <Route path='/select-patient' element={<ProtectedRoute> <SelectPatientPage/> </ProtectedRoute>}/>
            {/*<Route path='/analysis' element={<ProtectedRoute> <VideoAnalysisPage/> </ProtectedRoute>}/>*/}
            <Route path='/profile' element={<ProtectedRoute> <DoctorProfilePage/> </ProtectedRoute>}/>
            <Route path='/change-password' element={<ProtectedRoute> <ChangePasswordPage/> </ProtectedRoute>}/>
          </Routes>
          <Toaster position="top-right"/>
        </Layout>
      </ConfigProvider>
    </Router>
  );
}

export default App;
