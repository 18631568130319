import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { formFields } from "../../../assets/data/survey/survey-form-field";
import { DashboardWrapper } from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import { Button } from "../../../components/PatientProfile/PatientProfile.styles";
import PatientSurveyField from "../../../components/PatientSurvey";
import {
  FormContainer,
  FormWrapper,
  Title,
} from "../../../components/PatientSurvey/PatientSurvey.styles";
import { UserContext } from "../../../context/UserContext";
import useFormField from "../../../hooks/useFormField";
import { createSurvey } from "../../../utils/api-lists/patient.api";
import { GeneralBody } from "../../../widgets/GeneralBody";
import { Header } from "../styles";

const SubmitPatientSurvey = () => {
  const navigate = useNavigate();
  const {patientId} = useParams();
  const {user} = useContext(UserContext)
  const { t } = useTranslation("common");

  const initialValues = formFields.reduce((acc, field) => {
    acc[field.name] = field.type === "multi-select" ? [] : "";
    return acc;
  }, {});

  const validateForm = (formData) => {
    let errors = {};

    formFields.forEach((field) => {
      if (
        field.required &&
        (!formData[field.name] ||
          (Array.isArray(formData[field.name]) &&
            formData[field.name].length === 0))
      ) {
        errors[field.name] = `${field.label} is required`;
      }
    });

    return errors;
  };


  const {
    formData,
    formErrors,
    handleInputChange,
    handleReactSelectChange,
    handleReactMultiSelectChange,
    handleSubmit,
  } = useFormField(initialValues, validateForm,formFields);

  const onSave = async () => {
    try {
      await createSurvey(patientId, user.id, JSON.stringify(formData));
      navigate(`/patient-cabinet/${patientId}/patient-survey`,{state:{message:"Survey submission successful!",type:"success"}})
    } catch (error) {
      console.error("Error submitting survey:", error);
    }
  };

  return (
    <DashboardWrapper>
      <Header>
        <IconContext.Provider value={{ style: { cursor: "pointer" } }}>
          <FaArrowLeft onClick={() => navigate(-1)} />
        </IconContext.Provider>
        <h3>{t("lifeStyle_survey")}</h3>
      </Header>
      <GeneralBody>
        <FormContainer>
          <FormWrapper>
            <Title>{t("glacies_biome_questionare")}</Title>
            {formFields.map((field) => {
              return (
                <PatientSurveyField
                  key={field.name}
                  name={field.name}
                  field={field}
                  value={formData[field.name]}
                  error={formErrors[field.name]}
                  onChange={
                    field.type === "multi-select"||field.type==="creatable-multi-select"
                      ? handleReactMultiSelectChange
                      : field.type === "select"
                      ? handleReactSelectChange
                      : handleInputChange
                  }
                />
              );
            })}{" "}
            <Button
              onClick={(e) => handleSubmit(e, onSave)}
              className="max-w-44 mt-5"
            >
              {t("submit")}
            </Button>
          </FormWrapper>
        </FormContainer>
      </GeneralBody>
    </DashboardWrapper>
  );
};

export default SubmitPatientSurvey;
