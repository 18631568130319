import React, {useEffect, useState} from "react";
import ReportProductStep from "../../../components/ReportProductStep";
import TimeTag from "../../../components/Tags";
import {Button, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import mockUpImage from "../../../assets/mock_tube.jpg";
import {updateReport} from "../../../utils/api-lists";

const SkincareGuide = ({products, onStep, allProducts, report, updateProductList}) => {
  const [activeMorningStep, setActiveMorningStep] = useState(0);
  const [activeEveningStep, setActiveEveningStep] = useState(-1);
  const [IsProductModalOpen, setIsProductModalOpen] = useState(false);
  const [morningProducts, setMorningProducts] = useState([]);
  const [eveningProducts, setEveningProducts] = useState([]);
  const [notAddedProducts, setNotAddedProducts] = useState([]);

  const {t} = useTranslation("common");


  useEffect(() => {
    updateDayProducts(products);
  }, [products]);

  const updateDayProducts = (prod) => {
    setMorningProducts(prod?.filter((product) =>
      product.application_time.includes(1)
    ) ?? []);
    setEveningProducts(prod?.filter((product) =>
      product.application_time.includes(2)
    ) ?? []);

    const productsId = prod?.map((product) => product.id) ?? [];
    const notAddedProducts = allProducts.filter((product) => !productsId?.includes(product.id));
    setNotAddedProducts(notAddedProducts);
  }

  if (!products) {
    return <div>No Product Available</div>;
  }

  const handleMorningStepClick = (index) => {
    setActiveEveningStep(null);
    setActiveMorningStep(index);
    onStep(index);
  };

  const handleEveningStepClick = (index) => {
    setActiveMorningStep(null);
    setActiveEveningStep(index);
    onStep(index + morningProducts.length);
  };

  const toggleProductModal = () => {
    setIsProductModalOpen(!IsProductModalOpen);
  }

  function handleProductClick(product) {
    products.push(product);
    const allProductsId = products.map((product) => product.id);
    updateProductList(allProductsId);
    updateDayProducts(products);
    const reportData = {
      data: {
        ...report["data"],
        products: allProductsId
      },
      finalize: false,
    }
    updateReport(report.id, reportData);
    setIsProductModalOpen(false);
  }

  const onImgError = (e) => {
    e.target.onerror = null;
    e.target.src = mockUpImage;
  }

  return (
    <div>
      {morningProducts.length > 0 && (
        <>
          <TimeTag
            fullWidth
            type="morning"
            style={{margin: "30px 0 10px 0"}}
          />
          {morningProducts.map((product, index) => (
            <ReportProductStep
              key={product.id}
              imgSrc={product.image_url}
              productName={product.name}
              step={`Step: ${index + 1}`}
              onStep={() => handleMorningStepClick(index)}
              isActive={activeMorningStep === index}
            />
          ))}
          <Button color="purple" variant="solid" icon={<PlusOutlined/>} className="mt-3 w-full" onClick={() => {
            setIsProductModalOpen(true)
          }}>
            Add a new product
          </Button>
        </>
      )}
      {eveningProducts.length > 0 && (
        <>
          <TimeTag
            fullWidth
            type="evening"
            style={{margin: "40px 0 10px 0"}}
          />
          {eveningProducts.map((product, index) => {
            return <ReportProductStep
              key={product.id}
              imgSrc={product.image_url}
              productName={product.name}
              step={`Step: ${index + 1}`}
              onStep={() => handleEveningStepClick(index)}
              isActive={activeEveningStep === index}
            />
          })}
          <Button color="purple" variant="solid" icon={<PlusOutlined/>} className="mt-3 w-full" onClick={() => {
            setIsProductModalOpen(true)
          }}>
            Add a new product
          </Button>
        </>
      )}
      <Modal
        title={t("add_product")}
        open={IsProductModalOpen}
        footer={null}
        onCancel={toggleProductModal}
        destroyOnClose={true}
      >
        <div className="flex flex-col gap-3 whiteMode">
          {notAddedProducts?.length > 0
            ? notAddedProducts.map((product, index) => (
              <div className="bg-white p-3 w-full rounded-xl h-20 flex flex-row gap-3 hover:bg-gray-100 cursor-pointer"
                   onClick={() => handleProductClick(product)}>
                <div>
                  <img src={product.image_url} style={{
                    objectFit: "cover",
                    height: "100%",
                    width: '4rem'
                  }}
                       onError={onImgError}/>
                </div>
                <div className="flex flex-row gap-3 items-center">
                  <div className="flex flex-col gap-3">
                    <h1>{product.name}</h1>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
            ))
            : <h1 style={{color: "white !important"}}>No product available</h1>
          }
        </div>

      </Modal>
    </div>
  );
};

export default SkincareGuide;
