import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../../components/DashboardWrapper/DashboardWrapper.styles';
import {FaArrowLeft, FaRegTrashAlt} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {Header, ImageField, Images} from "../styles";
import CircleButton from "../../../widgets/AddIcon";
import {deletePatientFile, getPatientFilesList, postWirnklesAnalysis} from "../../../utils/api-lists";
import toast from "react-hot-toast";
import {IoMdRefresh} from "react-icons/io";
import {filterFileName} from "../../../utils/general.util";
import {FaXmark} from "react-icons/fa6";
import {DivRow} from "../../../widgets/GeneralBody";
import {useTranslation} from 'react-i18next';
import {Button, Divider, Modal, Spin} from "antd";
import {MdCompare} from "react-icons/md";
import styled from "styled-components";
import EyeWrinkle from "../../../assets/wrinkles/eyewrinkle.png";
import LipWrinkle from "../../../assets/wrinkles/lipwrinkle.png";
import ForeheadWrinkle from "../../../assets/wrinkles/wrinkle.png";

const PatientsCabinetPage = () => {
  const {patientId} = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [filesToCompare, setFilesToCompare] = useState([]);
  const {t} = useTranslation("common");

  // New states for wrinkles analysis
  const [isWrinklesModalOpen, setIsWrinklesModalOpen] = useState(false);
  const [wrinklesAnalysisLoading, setWrinklesAnalysisLoading] = useState(false);
  const [wrinklesAnalysisResult, setWrinklesAnalysisResult] = useState(null);

  function toggleModal(file) {
    setSelectedFile(file);
    setIsOpen(!isOpen);
  }

  function toggleWrinklesModal() {
    setIsWrinklesModalOpen(!isWrinklesModalOpen);
  }

  function getPatientFiles() {
    if (isLoading) return;
    setIsLoading(true);
    getPatientFilesList(patientId)
      .then((r) => {
        setFiles(r.data);
      })
      .catch((e) => {
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function deleteFile() {
    if (isLoading) return;
    setIsLoading(true);
    toast.promise(
      deletePatientFile(patientId, selectedFile['id']), {
        loading: 'Deleting...',
        success: 'Deleted successfully!',
        error: (e) => `Failed to delete file: ${e.response.data.message}`
      }
    )
      .catch(e => {
        console.error(e);
      })
      .finally(() => {
        setIsOpen(false);
        setIsLoading(false);
        getPatientFiles();
      })
  }

  const convertImageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]); // Get the Base64 string without the prefix
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function performWrinklesAnalysis() {
    if (wrinklesAnalysisLoading || !selectedFile) return;

    setWrinklesAnalysisLoading(true);
    setIsWrinklesModalOpen(true);

    try {
      const fetchImageResponse = await fetch(selectedFile['url'], {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });
      const imageBlob = await fetchImageResponse.blob();

      const imageFile = new File([imageBlob], selectedFile['image_name'], {
        type: fetchImageResponse.headers.get('content-type') || 'image/jpeg'
      });
      const base64Image = await convertImageToBase64(imageFile);

      const response = await toast.promise(
        postWirnklesAnalysis(base64Image), {
          loading: t('analyzing_wrinkles'),
          success: t('analysis_complete'),
          error: (e) => `${t('analysis_failed')}: ${e.response?.data?.message || t('unknown_error')}`
        }
      );

      setWrinklesAnalysisResult(response.data);
    } catch (e) {
      console.error('Error performing wrinkles analysis:', e);
    } finally {
      setWrinklesAnalysisLoading(false);
    }
  }

  function compareFiles() {
    console.log(filesToCompare);
    if (isCompare) {
      navigate({
        pathname: `compare`,
        search: `?file1Id=${filesToCompare[0]}&file2Id=${selectedFile['id']}`
      });
      return;
    }

    setFilesToCompare([...filesToCompare, selectedFile['id']]);
    setIsCompare(true);
    setIsOpen(false);
  }

  function clearCompare() {
    setSelectedFile([]);
    setIsCompare(false);
  }

  useEffect(() => {
    document.title = 'Visual analysis';
  }, []);

  useEffect(() => {
    getPatientFiles();
  }, [patientId]);

  const ImageWrapper = ({file}) => {
    return (
      <ImageField onClick={() => toggleModal(file)}>
        <img src={file['url']} alt="Image"/>
        <p>{filterFileName(file['image_name'])}</p>
      </ImageField>
    )
  }

  const VideoWrapper = ({file}) => {
    return (
      <ImageField onClick={() => toggleModal(file)}>
        <video controls>
          <source src={file['url']} type="video/mp4"/>
          Your browser does not support the video tag.
        </video>
        <p>{filterFileName(file['image_name'])}</p>
      </ImageField>
    )
  }

  const ShowFiles = () => {
    return (
      files.map((file, index) => {
        switch (file['type']) {
          case 'video':
            return <VideoWrapper key={file.id || index} file={file}/>;
          default:
          case 'image':
            return <ImageWrapper key={file.id || index} file={file}/>;
        }
      })
    )
  }

  const showWithFileType = () => {
    switch (selectedFile['type']) {
      case 'video':
        return (
          <video controls>
            <source src={selectedFile['url']} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        );
      default:
      case 'image':
        return <img src={selectedFile['url']} alt="Image"/>;
    }
  }

  // Render wrinkles analysis result
  const renderWrinklesAnalysisResult = () => {
    if (!wrinklesAnalysisResult) return null;

    const {results, mask} = wrinklesAnalysisResult;

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {mask && (
          <div className="text-center flex justify-between gap-3 rounded-xl overflow-hidden"
               style={{maxHeight: '25rem'}}>
            <img src={selectedFile['url']} alt="Image" style={{objectFit: 'cover', width: '100%', height: '100%'}}/>
            {/*<img src={mask} alt="Wrinkles Analysis Mask" className="max-w-full"*/}
            {/*     style={{objectFit: 'cover', width: '100%', height: '100%'}}/>*/}
          </div>
        )}

        <WrinkleWrapper className="flex flex-col gap-3 justify-between items-start bg-white p-3 rounded-xl">
          <h3 className="font-bold text-center text-black">Wrinkles level</h3>
          {WrinkleField({
            icon: EyeWrinkle,
            title: 'Eye',
            area: results['crows_feet']['area_mm\u00b2'],
            skeleton: 0.5,
            perimeter: 0.5,
            average: 0.5
          })}
          {WrinkleField({
            icon: LipWrinkle,
            title: 'Lips',
            area: 'forehead',
            skeleton: 0.5,
            perimeter: 0.5,
            average: 0.5
          })}
          {WrinkleField({
            icon: ForeheadWrinkle,
            title: 'Forehead',
            area: 'forehead',
            skeleton: 0.5,
            perimeter: 0.5,
            average: 0.5
          })}
        </WrinkleWrapper>
      </div>
    );
  };

  const WrinkleField = ({icon, title, area, skeleton, perimeter, average}) => {
    return (
      <div className="bg-[#f1f1f1] flex gap-3 justify-between rounded-xl p-3 w-full">
        <div className="shadow-xl bg-white rounded-xl flex flex-col items-center pb-2"
             style={{width: '5rem', height: '5rem'}}>
          <img src={icon} alt="Wrinkle Icon" className="w-full"
               style={{objectFit: 'cover', width: '3rem', height: '3rem'}}/>
          <p>{title}</p>
        </div>
        <div className="flex flex-row gap-3 items-center w-full justify-evenly">
          <div className="flex flex-col gap-2">
            <p>Area: {area}%</p>
            <p>Perimeter: {perimeter}mm</p>
          </div>

          <Divider type="vertical" style={{height: "100%", borderColor: '#888888'}}/>

          <div className="flex flex-col gap-2">
            <p>Skeleton Length: {skeleton}mm</p>
            <p>Avg. Width: {average}mm</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <DashboardWrapper>
      <Header>
        <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
          <FaArrowLeft onClick={() => navigate(-1)}/>
        </IconContext.Provider>
        <h3>{t("visual_analysis")}</h3>

        <CircleButton onClick={() => {
          getPatientFiles();
        }}>
          <IoMdRefresh/>
        </CircleButton>
        <CircleButton onClick={() => {
          navigate('file-upload');
        }}/>
      </Header>
      {
        isCompare &&
        <DivRow maxWidth={320} onClick={clearCompare}>
          <h4>Select file to Compare:</h4>
          <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
            <FaXmark/>
          </IconContext.Provider>
        </DivRow>
      }
      <Images>
        {
          ShowFiles()
        }
      </Images>

      {/* File details modal */}
      {
        selectedFile &&
        <Modal
          title={filterFileName(selectedFile['image_name'])}
          footer={null}
          onCancel={toggleModal}
          open={isOpen}>
          {selectedFile && showWithFileType({selectedFile})}
          <div className="flex flex-row justify-between gap-1 mt-3">
            {
              selectedFile['type'] === 'image' &&
              <Button type="primary" onClick={performWrinklesAnalysis}>
                {t("wrinkles_analyse")}
              </Button>
            }

            <div className="flex flex-row gap-1">
              <CircleButton onClick={compareFiles}>
                <MdCompare/>
              </CircleButton>
              <CircleButton onClick={() => deleteFile()} disabled={isLoading}>
                <FaRegTrashAlt/>
              </CircleButton>
            </div>
          </div>
        </Modal>
      }

      {/* Wrinkles analysis results modal */}
      <Modal
        title={t("wrinkles_analysis_results")}
        footer={null}
        open={isWrinklesModalOpen}
        onCancel={toggleWrinklesModal}
        width={{
          xs: '90%',
          sm: '90%',
          md: '90%',
          lg: '1000px',
          xl: '1000px',
          xxl: '1000px',
        }}>
        {wrinklesAnalysisLoading ? (
          <div className="flex justify-center items-center py-12">
            <Spin size="large" tip={t("analyzing")}/>
          </div>
        ) : (
          renderWrinklesAnalysisResult()
        )}
        {!wrinklesAnalysisLoading && (
          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={toggleWrinklesModal}>
              {t("close")}
            </Button>
          </div>
        )}
      </Modal>
    </DashboardWrapper>
  );
}

const WrinkleWrapper = styled.div`
    * {
        color: black;
    }
`

export default PatientsCabinetPage;