import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useFormField = (intialValue, validateForm,formFields) => {
  const [formData, setFormData] = useState(intialValue);
  const [formErrors, setFormErrors] = useState({});
  const {t} = useTranslation("common");

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const handleReactSelectChange = (selectedOption, { name }) => {
    setFormData({ ...formData, [name]: t(selectedOption.label) });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleReactMultiSelectChange = (selectedOptions, { name }) => {
    const field = formFields.find((f) => f.name === name);
    
    const options = field?.options || [];
  
    const hasAllOption = options.some((opt) => opt.value === "all");
  
    if (!selectedOptions) {
      setFormData({ ...formData, [name]: [] });
      return;
    }
  
    if (hasAllOption && selectedOptions.some((option) => option.value === "all")) {
      // Select all options except "All"
      setFormData({
        ...formData,
        [name]: options.filter((opt) => opt.value !== "all").map((option)=>t(option.label)),
      });
    } else {
      // Store selected options as objects
      setFormData({
        ...formData,
        [name]: selectedOptions.map((option=>t(option.label))),
      });
    }
  
    setFormErrors({ ...formErrors, [name]: "" });
  };


  const handleSubmit = (e, callback) => {
    e.preventDefault();

    const errors = validateForm(formData);

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      callback();
    }
  };

  return {
    formData,
    formErrors,
    handleInputChange,
    handleReactSelectChange,
    handleReactMultiSelectChange,
    handleSubmit,
  };
};

export default useFormField;
