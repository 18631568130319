import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {Button, Popup,} from "../../components/PatientProfile/PatientProfile.styles";
import {createReportRequest, getReportRequestsByPatient,} from "../../utils/api-lists/patient.api";
import {DashboardWrapper} from "../../components/DashboardWrapper/DashboardWrapper.styles";
import {Header, StyledModal} from "./styles";
import {GeneralBody} from "../../widgets/GeneralBody";
import {ListItemDiv} from "../../widgets/ListItem/ListItem.style";
import {format} from "date-fns";
import {UserContext} from "../../context/UserContext";
import CloseModal from "../../components/CloseModal";
import {ReportStatus} from "../../components/StatusBar/StatusBar.styles";

const PatientsReportRequest = () => {
  const navigate = useNavigate();
  const [requestReports, setRequestReports] = useState([]);
  const userContext = useContext(UserContext);
  const {patientId} = useParams();
  const {t} = useTranslation("common");
  const [isRequestCreated, setIsRequestCreated] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      getReportRequestsByPatient(patientId).then((data) => {
        setRequestReports(data.report_requests);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  }, [isRequestCreated]);

  const createReport = async () => {
    try {
      await createReportRequest(patientId, userContext.user.id); // Await the request
      setIsRequestCreated(true);
      setRequestMessage(t("create_report_request_succcess"));
    } catch (error) {
      setIsRequestCreated(true);
      setRequestMessage(t("create_report_request_failed"));
      console.error("Error creating report:", error);
    }
  };


  return (
    <DashboardWrapper>
      <Header>
        <IconContext.Provider value={{style: {cursor: "pointer"}}}>
          <FaArrowLeft onClick={() => navigate(-1)}/>
        </IconContext.Provider>
        <h3>{t("report_request")}</h3>
        <Button
          className="max-w-44"
          onClick={() => {
            createReport();
          }}
        >
          {t("request_new_report")}
        </Button>
      </Header>
      <GeneralBody>
        {requestReports.map((report, index) => {
          return (
            <ListItemDiv key={index}>
              <p className="mr-7">
                {t("request")}: {++index}
              </p>
              <ReportStatus className={report.status}>
                {t(report.status.toLowerCase())}
                {report.status !== "COMPLETED" && (
                  <span> {t("expected_to_be_ready_in_5_weeks")}</span>
                )}
              </ReportStatus>

              <p className="text-right">
                {format(report.requested_at, "MM-dd-yyyy HH:mma")}
              </p>
            </ListItemDiv>
          );
        })}
      </GeneralBody>
      <StyledModal
        isOpen={isRequestCreated}
        onBackgroundClick={() => setIsRequestCreated(false)}
        onEscapeKeydown={() => setIsRequestCreated(false)}
      >
        <Popup>
          <h3>{t("info")}</h3>
          <p>{requestMessage}</p>
        </Popup>
        <CloseModal
          onClick={() => {
            setIsRequestCreated(false);
          }}
        />
      </StyledModal>
    </DashboardWrapper>
  );
};

export default PatientsReportRequest;
