import api from "../api";

export const login = async (email, password) => {
  try {
    const response = await api.post(
      "/auth",
      { email, password },
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = response.data.response;

    if (data) {
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
        })
      );
      localStorage.setItem("token", data.token);

      return {
        id: data.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
        token: data.token,
      };
    } else {
      throw new Error("error_unexpected_response");
    }
  } catch (error) {
    console.error("Login error:", error);
    if (error.response) {
      if (error.response.status === 401) {
        throw new Error("error_incorrect_credentials");
      }
      if (error.response.status === 403) {
        throw new Error("error_unauthorized");
      }
      if (error.response.status >= 500) {
        throw new Error("error_server");
      }
    } else if (error.request) {
      throw new Error("error_no_response");
    } else {
      throw new Error("error_general");
    }
  }
};

export const register = async (
  firstName,
  lastName,
  email,
  password,
  userRole,
  organizationId,
  token
) => {
  try {
    const response = await api.post(
      "/register",
      {
        firstName,
        lastName,
        email,
        password,
        userRole,
        organizationId,
        token
      },
      {}
    );

    if (!(response.status === 201)) {
      throw new Error("error_registration_failed");
    }

    const data = await response.data;

    if (data) {
      return true;
    } else {
      throw new Error("error_registration_failed");
    }
  } catch (error) {
    throw error.message;
  }
};

export const forgetPassword = async (email) => {
  try {
    const response = await api.post(
      "/forget-password",
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!(response.status === 200)) {
      throw new Error("error_invalid_email");
    }

    const data = await response.data;

    if (data) {
      return true;
    } else {
      throw new Error("error_invalid_email");
    }
  } catch (error) {
    throw error.message;
  }
};

export const changePassword = async (email, oldPassword, newPassword) => {
  try {
    const response = await api.post(
      "/change-password",
      { email, oldPassword, newPassword },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!(response.status === 200)) {
      throw new Error("error_password_change_failed");
    }

    const data = await response.data;

    if (data) {
      return true;
    } else {
      throw new Error("error_password_change_failed");
    }
  } catch (error) {
    throw error.message;
  }
};

export const logout = () => {
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

export const isAuthenticated = () => {
  return localStorage.getItem("isAuthenticated") === "true";
};
