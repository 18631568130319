import React from 'react';
import SmallBoxComponent from "./small-box";
import styled from "styled-components";

const AgeComponent = ({age, info}) => {
  return (
    <SmallBoxComponent
      title={'Skin Age'}
      info={info}
      child={
        AgeParts({age})
      }

    />
  );
}

const AgeParts = ({age}) => {
  return (
    <AgeStyle className={'flex justify-between flex-col items-center'}>
      <h1>{age}</h1>
    </AgeStyle>
  )
}

const AgeStyle = styled.div`
    h1 {
        margin-top: 12px;
        font-size: 64px;
        color: #6100ff;
    }
`

export default AgeComponent;
