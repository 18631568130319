import React from 'react';
import SmallBoxComponent from "./small-box";
import styled from "styled-components";

const SkinComponents = ({skin_type,info}) => {
    return (
        <SmallBoxComponent
            title={'Skin Index'}
            info={info}
            child={
                SkinType({skin_type})
            }

        />
    );
}

const SkinType = ({skin_type}) => {
    return (
        <SkinStyle className={'flex justify-between flex-col items-center'}>
            <h1>{skin_type}</h1>
        </SkinStyle>
    )
}

const SkinStyle = styled.div`
    h1 {
        margin-top: 12px;
        font-size: 28px;
        color: #6100ff;
    }
`

export default SkinComponents;
