export const skinTypeCombinations = [
    {
        name: 'OSPW',
        types: ['Oily', 'Sensitive', 'Pigmented', 'Wrinkle-Prone'],
        description: 'OSPW skin is characterized by excessive oil production, sensitivity to environmental factors, a tendency for hyperpigmentation, and early wrinkle formation. This skin type requires a balance of oil control, soothing ingredients, and targeted anti-aging treatments to reduce fine lines while preventing dark spots.'
    },
    {
        name: 'OSPT',
        types: ['Oily', 'Sensitive', 'Pigmented', 'Tight'],
        description: 'OSPT skin is oily and sensitive, with pigmentation concerns but minimal wrinkles. Managing oil production while soothing sensitivity and preventing dark spots are top priorities. A routine with calming ingredients, gentle exfoliation, and brightening serums can help.'
    },
    {
        name: 'OSNW',
        types: ['Oily', 'Sensitive', 'Non-Pigmented', 'Wrinkle-Prone'],
        description: 'OSNW skin has high sebum production, making it prone to acne and breakouts, while also being sensitive. Despite its resilience to pigmentation, it develops wrinkles over time. Anti-aging treatments with antioxidants and retinol are recommended.'
    },
    {
        name: 'OSNT',
        types: ['Oily', 'Sensitive', 'Non-Pigmented', 'Tight'],
        description: 'OSNT skin is oily and easily irritated but does not develop pigmentation or wrinkles quickly. The key is using lightweight, hydrating formulas that control oil while soothing sensitivity.'
    },
    {
        name: 'ORPW',
        types: ['Oily', 'Resistant', 'Pigmented', 'Wrinkle-Prone'],
        description: 'ORPW skin is highly resilient but prone to both pigmentation and wrinkles. A skincare routine with exfoliating acids, vitamin C, and retinol can help combat dark spots and fine lines while maintaining firmness.'
    },
    {
        name: 'ORPT',
        types: ['Oily', 'Resistant', 'Pigmented', 'Tight'],
        description: 'ORPT skin produces excess sebum but is strong against external irritants and aging. However, pigmentation remains a concern. A routine with brightening serums and sun protection is essential.'
    },
    {
        name: 'ORNW',
        types: ['Oily', 'Resistant', 'Non-Pigmented', 'Wrinkle-Prone'],
        description: 'ORNW skin is resilient and does not struggle with pigmentation, but it is prone to wrinkles. Anti-aging ingredients like peptides, retinoids, and hydrating serums are essential.'
    },
    {
        name: 'ORNT',
        types: ['Oily', 'Resistant', 'Non-Pigmented', 'Tight'],
        description: 'ORNT skin is one of the least problematic types, as it resists irritation, pigmentation, and premature aging. The main focus is controlling excess oil and maintaining hydration.'
    },
    {
        name: 'DSPW',
        types: ['Dry', 'Sensitive', 'Pigmented', 'Wrinkle-Prone'],
        description: 'DSPW skin struggles with dryness, irritation, pigmentation, and premature wrinkles. It requires deep hydration, barrier repair, and sun protection to maintain a smooth and youthful appearance.'
    },
    {
        name: 'DSPT',
        types: ['Dry', 'Sensitive', 'Pigmented', 'Tight'],
        description: 'DSPT skin lacks moisture and is prone to irritation and pigmentation but does not develop wrinkles as quickly. Hydration and brightening treatments are essential.'
    },
    {
        name: 'DSNW',
        types: ['Dry', 'Sensitive', 'Non-Pigmented', 'Wrinkle-Prone'],
        description: 'DSNW skin is prone to dryness, irritation, and premature aging, though it does not struggle with pigmentation. Deep hydration and anti-aging treatments help maintain a smooth complexion.'
    },
    {
        name: 'DSNT',
        types: ['Dry', 'Sensitive', 'Non-Pigmented', 'Tight'],
        description: 'DSNT skin is dry and reactive but does not develop wrinkles or pigmentation easily. Maintaining hydration and barrier repair is key.'
    },
    {
        name: 'DRPW',
        types: ['Dry', 'Resistant', 'Pigmented', 'Wrinkle-Prone'],
        description: 'DRPW skin is strong and less reactive but is prone to pigmentation and wrinkles. Anti-aging and brightening treatments help maintain skin health.'
    },
    {
        name: 'DRPT',
        types: ['Dry', 'Resistant', 'Pigmented', 'Tight'],
        description: 'DRPT skin does not wrinkle early but struggles with dryness and pigmentation. A routine with hydration and brightening serums is essential.'
    },
    {
        name: 'DRNW',
        types: ['Dry', 'Resistant', 'Non-Pigmented', 'Wrinkle-Prone'],
        description: 'DRNW skin is dry, resistant to irritation, and tends to develop wrinkles. Although not prone to pigmentation, hydration and anti-aging treatments are key priorities. Deep hydration and collagen-boosting ingredients can help maintain a youthful appearance.'
    },
    {
        name: 'DRNT',
        types: ['Dry', 'Resistant', 'Non-Pigmented', 'Tight'],
        description: 'DRNT skin is dry but resilient, with minimal concerns about pigmentation or aging. The focus should be on hydration and maintaining a healthy barrier.'
    }
];