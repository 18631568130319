import api from '../api';

export const verifyEmail = async (email, token) => {
    try {
        const response = await api.post(`/verify`, 
            { email, token },
            {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status !== 200 && response.status !== 202) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const checkVerificationStatus = async (token) => {
    try {
        const response = await api.get(`/verification-status`, {
            params: { token },
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!(response.status === 200 || response.status === 202)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const checkInvitationStatus = async(token)=>{
    try {
        const response = await api.get(`/invitation-status`, {
            params: { token },
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!(response.status === 200 || response.status === 202)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw new Error(error.message);
    }
}
