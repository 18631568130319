import React, {useCallback, useEffect, useRef, useState} from "react";
import {CamControls, Wrapper} from "./Camera.styles";
import CircleButton from "../../widgets/AddIcon";
import {FaCamera, FaRegDotCircle, FaRegStopCircle} from "react-icons/fa";
import {FaCameraRotate} from "react-icons/fa6";
import FaceDetection from "../FaceDetection/FaceDetection";
import {Button, Checkbox} from "antd";
import {CheckOutlined} from "@ant-design/icons";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const Camera = (props) => {
  const webcamRef = useRef(null); // create a webcam reference
  const mediaRecorderRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const [imgSrc, setImgSrc] = useState(null);
  const [mirrored, setMirrored] = useState(true);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [isVideo, setIsVideo] = useState(props.isVideo);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [capturing, setCapturing] = React.useState(false);
  const [statusMessages, setStatusMessages] = useState([]);

  // Ref to store the clean capture function provided by FaceDetection
  const cleanCaptureRef = useRef(null);

  // Track modal open state
  useEffect(() => {
    setIsOpen(props.isOpen);

    // When modal closes, stop recording and clean up
    if (!props.isOpen) {
      if (capturing) {
        handleStopCaptureClick();
      }

      // Clean up media stream when modal closes
      if (webcamRef.current && webcamRef.current.stream) {
        const tracks = webcamRef.current.stream.getTracks();
        tracks.forEach(track => {
          track.stop();
        });
      }
    }
  }, [props.isOpen]);

  const changeCamera = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  // Handler to receive the clean capture function from FaceDetection
  const handleCaptureSetup = useCallback((captureFunc) => {
    cleanCaptureRef.current = captureFunc;
  }, []);

  // Modified capture function that takes a clean screenshot from the camera
  const capture = useCallback(() => {
    // First try to use the clean capture function from FaceDetection
    if (cleanCaptureRef.current) {
      try {
        const cleanImage = cleanCaptureRef.current();
        if (cleanImage) {
          console.log('Clean image captured:', cleanImage.substring(0, 30));
          setImgSrc(cleanImage);
          return;
        }
      } catch (error) {
        console.error('Error using clean capture:', error);
        // Continue to fallback methods
      }
      setIsOpen(false);
    }

    // Fallback to direct webcam capture if clean capture fails
    if (webcamRef.current) {
      try {
        const webcamImage = webcamRef.current.getScreenshot();
        console.log('Direct webcam capture:', webcamImage ? webcamImage.substring(0, 30) : 'null');
        setImgSrc(webcamImage);
      } catch (webcamError) {
        console.error('Webcam capture failed:', webcamError);
      }
    }
  }, [webcamRef, cleanCaptureRef]);

  const retake = () => {
    setImgSrc(null);
    setIsOpen(true);
  };

  const handleStartCaptureClick = React.useCallback(() => {
    if (webcamRef.current && webcamRef.current.stream) {
      setCapturing(true);
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    } else {
      console.error("Webcam stream is not available.");
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({data}) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  useEffect(() => {
    if (recordedChunks.length) {
      saveVideo();
    }
  }, [recordedChunks]);

  const handleStopCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  function randomString(length) {
    return Math.random().toString(36).substring(2, 2 + length);
  }

  const saveVideo = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      setRecordedChunks([]);
      var file = new File([blob], `${randomString(12)}.webm`, {lastModified: Date.now()});
      props.saveVideo(file);
    }
  }, [recordedChunks, props]);

  const handleStatusChange = (status) => {
    setStatusMessages(status.statusMessages);
  }

  return (
    <Wrapper>
      <div className={"cameraContainer"}>
        {/* Conditionally render FaceDetection based on isOpen state */}
        {isOpen && (
          <FaceDetection
            webcamRef={webcamRef}
            mirror={mirrored}
            facingMode={facingMode}
            onStatusChange={handleStatusChange}
            onCapture={handleCaptureSetup} // Pass the callback to receive the clean capture function
            hide={imgSrc !== null}
          />
        )}

        {imgSrc && <div className={"imagePreview"}>
          <img src={imgSrc} alt="webcam"/>
          {
            statusMessages.length > 0 && (
              <div
                id="status"
                className="statusMessages"
                style={{
                  minWidth: '300px',
                  padding: '15px',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  backgroundColor: '#f9f9f9',
                  marginTop: '20px'
                }}
              >
                {statusMessages.map((msg, index) => (
                  <p
                    key={index}
                    style={{
                      color: msg.type === 'success' ? 'green' :
                        msg.type === 'error' ? 'red' :
                          msg.type === 'warning' ? 'orange' : 'black',
                      margin: '2px 0'
                    }}
                  >
                    {msg.text}
                  </p>
                ))}
              </div>
            )}
        </div>}
      </div>
      <CamControls>
        {imgSrc ? (
          <>
            {/*<Button type="primary" shape="circle" icon={<DeleteOutlined/>} size={'large'} onClick={retake}/>*/}
            <Button type="primary" shape="circle" icon={<CheckOutlined/>} size={'large'} onClick={() => {
              props.saveImage(imgSrc);
            }}/>
          </>
        ) : (
          <>
            <div>
              <Checkbox checked={mirrored} onChange={(e) => setMirrored(e.target.checked)}>Mirror</Checkbox>
            </div>
            {isVideo ? (
              <>
                {capturing ? (
                  <CircleButton onClick={handleStopCaptureClick}>
                    <FaRegStopCircle/>
                  </CircleButton>
                ) : (
                  <CircleButton onClick={handleStartCaptureClick}>
                    <FaRegDotCircle/>
                  </CircleButton>
                )}
              </>
            ) : (
              <CircleButton onClick={capture}>
                <FaCamera/>
              </CircleButton>
            )}
            <CircleButton onClick={changeCamera}>
              <FaCameraRotate/>
            </CircleButton>
          </ >
        )}
      </CamControls>
    </Wrapper>
  )
};

export default Camera;