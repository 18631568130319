import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_it from "./translations/it/common.json";
import common_es from "./translations/es/common.json";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    common: common_en,
  },
  it: {
    common: common_it,
  },
  es: {
    common: common_es,
  },
};

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("language") || "en",
  fallbackLng: "en",
  resources,
});

export default i18next;
