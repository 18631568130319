import React, {createContext, useEffect, useState} from 'react';
import api from "../../../utils/api";

const RegisterKitContext = createContext();

const RegisterKitProvider = ({children}) => {
    const [registerKits, setRegisterKits] = useState([]);
    // const {patientId} = useParams();

    useEffect(() => {
    }, []);

    const getRegisterKits = async (patientId) => {
        if (registerKits.length > 0) {
            return registerKits;
        }
        try {
            const response = await api.get(`/patients/${patientId}/kits`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch reports');
            }
            const data = await response.data;
            setRegisterKits(data['kits']);
            return data['kits'];
        } catch (error) {
            throw error;
        }
    }


    return (
        <RegisterKitContext.Provider
            value={{
                registerKits,
                setRegisterKits,
                getRegisterKits
            }}
        >
            {children}
        </RegisterKitContext.Provider>
    );
};

export {RegisterKitContext, RegisterKitProvider};
