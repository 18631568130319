import axios from "axios";
import {logout} from "./api-lists/login.api";

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Adding a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Set the `Access-Control-Allow-Origin` header for every request
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Access-Control-Allow-Methods"] =
            "GET, POST, PUT, DELETE, OPTIONS";
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json";
        }

        const token = localStorage.getItem("token");
        if (!!token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.config.url.includes("/auth")) {
            return Promise.reject(error);
        }
        if (error.response.status === 403 || error.response.status === 401 || error.response.status === 422) {
            logout();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
