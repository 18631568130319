import React, {useRef, useState} from "react";
import {AccardionStyle} from "./Accardion.style";
import {FiMinusCircle, FiPlusCircle} from "react-icons/fi";

const Accordion = ({title, children, isOpen = true, rightAction, closable = true}) => {
  const contentHeight = useRef();
  const [open, setOpen] = useState(isOpen);
  

  function openAcc(val) {
    if (closable) {
      setOpen(val);
    }
  }

  return (
    <AccardionStyle>
      <button className={`question-container ${open ? 'active' : ''}`} onClick={() => openAcc(!open)}>
        <p className='question-content font-bold'>{title}</p>
        {
          rightAction
            ? rightAction
            : open
              ? <FiMinusCircle style={{fontSize: 32}}/>
              : <FiPlusCircle style={{fontSize: 32}}/>
        }
      </button>

      {
        closable ?
          <div ref={contentHeight} className="answer-container" style={
            open
              ? {height: contentHeight.current?.scrollHeight}
              : {height: "0px"}
          }>
            <div className="answer-content pb-3">
              {children}
            </div>
          </div>
          :
          <div className="answer-container">
            <div className="answer-content pb-3">
              {children}
            </div>
          </div>
      }
    </AccardionStyle>
  )
};

export default Accordion;
