export const filterFileName = (fileName) => {
  return fileName?.split("_").slice(1).join("_");
};

export const copyUrlToClipboard = (path, onSucces, onError) => {
  navigator.clipboard
    .writeText(`${window.location.origin}${path}`)
    .then(() => {
      if (onSucces) onSucces();
    })
    .catch(() => {
      if (onError) onError();
    });
};
