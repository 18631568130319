import styled from "styled-components";
import React from 'react';
import TooltipWidget from "./tooltip.widget";

// Components


const SmallBoxComponent = ({title, child, info}) => {
  return (
    <SmallBox>
      <div className={'flex justify-between p-2 items-center'}>
        <h5>{title}</h5>
        <TooltipWidget message={info} title={title}/>
      </div>
      <div>
        {child}
      </div>
    </SmallBox>
  );
}

export default SmallBoxComponent;

const SmallBox = styled.div`
    width: 120px;
    height: 120px;
    background-color: #ffffff;
    border-radius: 8px;

    * {
        color: #a1a2ff;
    }

    h5 {
        font-weight: bold;

        color: #5c466b;
    }
`;