import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {FullScreeen} from './widget/sharedPage.styles';
import {formFields} from '../../assets/data/survey/survey-form-field';
import {FormContainer, FormWrapper, Title} from '../../components/PatientSurvey/PatientSurvey.styles';
import {useTranslation} from 'react-i18next';
import useFormField from '../../hooks/useFormField';
import PatientSurveyField from '../../components/PatientSurvey';
import {Button} from '../../components/PatientProfile/PatientProfile.styles';
import {createSurvey} from "../../utils/api-lists/patient.api";
import Alert from "../../components/Alert";


const PatientSurveySubmitShare = () => {
  const {t} = useTranslation("common");
  const location = useLocation();

  const [isSent, setIsSent] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const patientId = queryParams.get('patientId');
  const doctorId = queryParams.get('doctorId');

  const [alert, setAlert] = useState({message: null, type: null});

  const initialValues = formFields.reduce((acc, field) => {
    acc[field.name] = field.type === "multi-select" || "creatable-multi-select" ? [] : "";
    return acc
  }, {})

  const validateForm = (formData) => {
    let errors = {};

    formFields.forEach((field) => {
      if (
        field.required &&
        (!formData[field.name] ||
          (Array.isArray(formData[field.name]) &&
            formData[field.name].length === 0))
      ) {
        errors[field.name] = `${field.label} is required`;
      }
    });

    return errors;
  };

  const onSave = async () => {
    setIsSent(true);
    try {
      await createSurvey(patientId, doctorId, JSON.stringify(formData));
      setAlert({message: 'Successfully sent your Survey', type: 'success'});
    } catch (error) {
      console.error("Error submitting survey:", error);
      setAlert({message: 'Failed to sent your Survey', type: 'error'});
      setIsSent(false);
    }
  };


  const {
    formData,
    formErrors,
    handleInputChange,
    handleReactSelectChange,
    handleReactMultiSelectChange,
    handleSubmit,
  } = useFormField(initialValues, validateForm, formFields);


  return (
    <FullScreeen>
      <FormContainer>
        <FormWrapper>
          <Title>{t("glacies_biome_questionare")}</Title>
          {formFields.map((field) => {
            return (
              <PatientSurveyField
                key={field.name}
                name={field.name}
                disabled={isSent}
                field={field}
                value={formData[field.name]}
                error={formErrors[field.name]}
                onChange={
                  field.type === "multi-select" || field.type === "creatable-multi-select"
                    ? handleReactMultiSelectChange
                    : field.type === "select"
                      ? handleReactSelectChange
                      : handleInputChange
                }
              />
            )
          })}
          {alert.message && alert.type && (
            <Alert message={alert.message} type={alert.type}/>
          )}
          {
            !isSent &&
            <Button className="max-w-44 mt-5" onClick={(e) => handleSubmit(e, () => {
              onSave();
            })}>{t("submit")}</Button>
          }
        </FormWrapper>
      </FormContainer>
    </FullScreeen>
  );
}

export default PatientSurveySubmitShare;
