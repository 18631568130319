import React,{useEffect, useState} from "react";
import { AlertContainer, AlertIcon, AlertMessage, CloseButton } from "./Alert.styles";
import { FaCheckCircle, FaTimesCircle, FaInfoCircle,FaTimes } from "react-icons/fa";

const Alert = ({ type, message,onClose=()=>{} }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(()=>{
    const timer = setTimeout(() => {
      setIsVisible(false)
      onClose();
    }, 2000);

    return ()=>clearTimeout(timer)
  },[onclose])

  const icons = {
    success: <FaCheckCircle />,
    error: <FaTimesCircle />,
    info: <FaInfoCircle />,
  };

  return (
    isVisible && (
      <AlertContainer type={type}>
        <AlertIcon>{icons[type]}</AlertIcon>
        <AlertMessage>{message}</AlertMessage>
        <CloseButton onClick={() => setIsVisible(false)}><FaTimes/></CloseButton>
      </AlertContainer>
    )
  );
};

export default Alert;
