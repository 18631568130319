export const diversityIndex = [
    {
        range: [0, 0.6],
        description: 'Microbial diversity on your face is at lower range. Your personalized skincare routine will help you to restore the microbiome diversity on your skin.'
    },
    {
        range: [0.6, 0.8],
        description: 'Microbial diversity on your face is at optimal range. Your personalized skincare routine will help you to maintain the microbiome diversity on your skin.'
    },
    {
        range: [0.8, 1],
        description: 'Microbial diversity on your face is at higher range. Your personalized skincare routine will help you to restore the microbiome diversity on your skin.'
    }
];