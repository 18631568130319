import styled from "styled-components";

const AlertContainer = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  ${({ type }) =>
    type === "success"
      ? "background-color:rgb(6, 92, 47); color: #065f46; border: 1px solid #34d399;"
      : type === "error"
      ? "background-color:rgb(242, 16, 16); color: #b91c1c; border: 1px solid #f87171;"
      : type === "info"
      ? "background-color:rgb(21, 74, 250); color: #1e3a8a; border: 1px solid #93c5fd;"
      : "background-color: #fff; color: #000;"}
`;

// Icon for the alert
const AlertIcon = styled.div`
  margin-right: 0.5rem;
  font-size: 1.25rem;
`;

const AlertMessage = styled.div`
  flex-grow: 1;
`;

const CloseButton = styled.button`
  top: 0.25rem;
  right: 0.25rem;
  background: transparent;
  border: none;
  font-size: 1.25rem;
  color: inherit;
  cursor: pointer;

  &:hover {
    color: #9ca3af;
  }
`;

export{AlertContainer, AlertIcon, AlertMessage,CloseButton}