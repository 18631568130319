import { format } from "date-fns";
import { enUS,es,it } from "date-fns/locale";

const locales = {
    en: enUS,
    es: es,
    it: it,
}

export const formatDate = (date)=>{
    const storedLocale = localStorage.getItem('language');
    const locale = locales[storedLocale] || enUS;
    return format(date, 'MMMM dd, yyyy H:mma', { locale });
}