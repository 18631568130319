import api from '../api';

export const getDoctor = async (doctorId) => {
    try {
        const response = await api.get(`/doctor/${doctorId}`, {
            credentials: 'include',
            headers: {}
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const updateDoctorProfilePicture = async (doctorId, file) => {
    try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await api.patch(`/doctor/${doctorId}/profile-picture`, formData, {
            credentials: 'include',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
}