export const skincareRoutine = [
    {
        type: 'Cleanser',
        step: 1,
        why: "A gentle, hydrating cleanser removes impurities without stripping the skin's natural oils, helping to maintain moisture balance in dry skin.",
        how: "Use first thing in the morning and before sleep."
    },
    {
        type: 'Toner',
        step: 2,
        why: "Toner helps restore the skin’s pH balance after cleansing while preparing it to absorb serums and moisturizers more effectively. It also removes any remaining residue.",
        how: "Apply a small amount onto a cotton pad or your hands and gently pat it onto the skin."
    },
    {
        type: 'Serum',
        step: 3,
        why: "While other steps in your skincare routine help maintain your skin balance, specialized serums are the most crucial stage in directly addressing problems in your facial routine.\nSince the serum stays on your face overnight, it penetrates directly to the root of the issues, not just the symptoms on your face.",
        how: "Use the serum before going to bed right after cleanser."
    },
    {
        type: 'Moisturizer',
        step: 4,
        why: "As the final step in your skincare routine, the moisturizer helps to keep other active ingredients on your face and restores the skin's moisture lost throughout the day.",
        how: "Apply it as the last step of your skincare routine both in the morning and in the evening."
    },
    {
        type: 'Sunscreen',
        step: 5,
        why: "Considering that you have a fair skin, a SPF will be required to protect against ultraviolet rays from the sun.\nHowever, using SPF is particularly important on days when you use a serum containing retinol.",
        how: "It should be used before sun exposure during the day and reapplied every 2 hours."
    }
]