import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
        gap: 20px;
        flex-direction: column;
        width: 100%;
        .hide-on-mobile {
            display: none;
        }
    }
`;

export const Section = styled.div`
    img {
        width: 70px;
        height: 70px;
    }

    h3 {
        font-weight: 600;
        margin-bottom: 8px;
    }

    p {
        line-height: 24px;
    }

    .btn {
        &.btn-2 {
            background-color: #2775CE; /* Keep the blue background */
            color: #ffffff;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 10px;
            width: 200px; /* Ensure the button has the same width as visit items */
            height: 40px; /* Ensure the button has the same height as visit items */
            text-align: center; /* Center the text inside the button */
            line-height: 18px; /* Adjust the line height to center the text vertically */
        }

        &.btn-align-right {
            float: right;
        }
    }
`;

export const Popup = styled.div`
    //position: absolute;
    width: 567px;
    @media (max-width: 700px) {
        width: 80vw;
    }
    //height: 484px;
    background: #555555;
    border-radius: 10px;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    padding: 54px 84px 27px 83px;
    z-index: 1000;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    transform: rotate(45deg);
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Input = styled.input`
    width: 100%;
    height: 50px;
    background: #202020;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    padding: 10px;
`;

export const TextArea = styled.textarea`
    width: 100%;
    height: 200px;
    background: #202020;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    padding: 10px;
    resize: none;
`;

export const Button = styled.button`
    width: 100%;
    height: 50px;
    background: #2775CE;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    cursor: pointer;
`;

export const VisitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 80px; /* Add margin to push elements below */
`;

export const VisitItem = styled.div`
    height: 40px;
    background: ${({isButton}) => (isButton ? 'none' : '#555555')};
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .btn {
        width: auto;
        padding: 0 !important;
    }
`;

export const DateText = styled.p`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
`;
