import React from "react";
import {ErrorText, FormGroup, Input, Label, selectStyles,} from "./PatientSurvey.styles";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

const PatientSurveyField = ({field, value, error, onChange, disabled = false}) => {
  const {t} = useTranslation("common");

  const translatedOptions = field.options?.map((option) => ({
    ...option,
    label: t(option.label),
  }));
  
  return (
    <FormGroup>
      {/* Handle Text and Number Inputs */}
      {field.type === "text" || field.type === "number" ? (
        <FormGroup>
          <Label>{t(field.label)}</Label>
          <Input
            type={field.type}
            name={field.name}
            value={value}
            disabled={disabled}
            onChange={onChange}
          />
        </FormGroup>
      ) : field.type === "select" ? (
        /* Handle Single Select Dropdown */
        <FormGroup>
          <Label>{t(field.label)}</Label>

          <Select
            isDisabled={disabled}
            name={field.name}
            value={translatedOptions.find((option) => option.value === value)}
            options={translatedOptions}
            styles={selectStyles}
            onChange={(selectedOption) => onChange(selectedOption, field)}
          />
        </FormGroup>
      ) : field.type === "multi-select" ? (
        /* Handle Multi-Select Dropdown */
        <FormGroup>
          <Label>{t(field.label)} <span>{t("select_multiple_options")}</span></Label>
          <Select
            name={field.name}
            isDisabled={disabled}
            value={translatedOptions.filter((option) =>
              value.includes(option.label)
            )}
            options={translatedOptions}
            styles={selectStyles}
            isMulti
            closeMenuOnSelect={false}
            onChange={(selectedOptions) => onChange(selectedOptions, field)}
          />
        </FormGroup>
      ) : field.type === "creatable-multi-select" ? (
        <FormGroup>
          <Label>{t(field.label)} <span>{t("select_multiple_create")}</span></Label>
          <CreatableSelect
            name={field.name}
            isDisabled={disabled}
            value={translatedOptions.filter((option) =>
              value.includes(option.label)
            )}
            options={translatedOptions|| []}
            styles={selectStyles}
            isMulti
            closeMenuOnSelect={false}
            onChange={(selectedOptions) => onChange(selectedOptions, field)}
          />
        </FormGroup>
      ) : null}
      {error && <ErrorText>{error}</ErrorText>}
    </FormGroup>
  );
};

export default PatientSurveyField;
