export const skinTypesDescription = [
    {
        type: "Oily",
        description: "Oily skin is characterized by an overproduction of sebum, giving the skin a shiny appearance. This excess oil can lead to clogged pores and the formation of acne, blackheads, or other blemishes. Managing oily skin requires skincare routines that control sebum production while maintaining adequate hydration to prevent over-drying."
    },
    {
        type: "Dry",
        description: "Dry skin lacks sufficient moisture and natural oils, resulting in flakiness, tightness, and a rough texture. It is more prone to irritation, itchiness, and the development of fine lines. Hydrating products, rich creams, and consistent moisturization are key to maintaining comfort and health in dry skin."
    },
    {
        type: "Sensitive",
        description: "Sensitive skin reacts quickly to external triggers such as harsh skincare products, environmental factors, or stress. Common signs include redness, itchiness, burning, or irritation. Gentle, fragrance-free formulations are essential to maintain balance and reduce flare-ups for this skin type."
    },
    {
        type: "Resistant",
        description: "Resistant skin is less reactive to environmental aggressors and skincare products, showing fewer signs of irritation or redness. While this skin type is robust, its thick texture and slower cell turnover may lead to a dull appearance, requiring exfoliation and brightening products for maintenance."
    },
    {
        type: "Pigmented",
        description: "Pigmented skin is prone to issues such as uneven skin tone, dark spots, and discoloration. These conditions often result from sun exposure, hormonal changes, or post-inflammatory hyperpigmentation. Effective sun protection and the use of brightening serums can help to even out the complexion."
    },
    {
        type: "Non-Pigmented",
        description: "Non-Pigmented skin maintains an even tone and rarely develops noticeable discoloration or dark spots. While it appears clear and balanced, this skin type still benefits from protection against UV rays and pollutants to preserve its health and radiance."
    },
    {
        type: "Tight",
        description: "Tight skin feels taut and lacks elasticity, often due to dehydration, aging, or environmental factors. This condition can cause discomfort and increase the visibility of wrinkles and fine lines. Products that restore hydration and boost collagen are essential for improving tight skin."
    },
    {
        type: "Wrinkle-Prone",
        description: "Wrinkle-prone skin shows visible signs of aging, such as fine lines, deeper creases, and sagging. These changes result from a loss of collagen, elasticity, and hydration over time. Anti-aging products with antioxidants, retinoids, and sunscreen are crucial for prevention and care."
    }
];