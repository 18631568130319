import React, {useEffect, useState} from 'react';
import {
  Cell,
  Dot,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import styled from "styled-components";
import {bacteriaLevelInfo} from "../../../assets/data/bacteria-level-info";

const COLORS = ['#da6561', '#55c575', '#da6561'];

export const ScatterChartWidget = ({num = 80, nums = [], name, min = 0, max = 100}) => {
  const [data, setData] = useState([]);
  const bacteriaLevels = bacteriaLevelInfo;

  const range = bacteriaLevels.find((b) => b.name === name).range;

  const isInRangeColor = (value) => {
    if (value >= range[1]) {
      return COLORS[0];
    } else if (value < range[0]) {
      return COLORS[0];
    } else {
      return COLORS[1];
    }
  }

  useEffect(() => {
    if (nums.length > 0) {
      // Use index as x value for equal spacing
      const dat = nums.map((n, i) => {
        const date = new Date(n.date);
        const monthYear = date.toLocaleDateString('en-US', {month: 'short', year: 'numeric'});
        let value = n.data;
        if (value > max) {
          value = max;
        }
        if (value < min) {
          value = min;
        }
        return {
          x: i, // Use index for equal spacing
          actualDate: date.getTime(), // Keep actual date for tooltips
          y: value,
          name: monthYear,
          value: `${n.data}%`,
          index: i
        };
      });
      setData(dat);
    }
  }, [nums]);

  // Generate custom ticks for equally spaced months
  const customTicks = data.map(item => item.x);

  // Custom tick formatter for the x-axis
  const formatXAxis = (index) => {
    const item = data.find(d => d.x === index);
    if (item) {
      return item.name;
    }
    return '';
  };

  // Custom reference lines from each data point to x-axis
  const ReferenceLines = () => {
    return (
      <>
        {data.map((entry, index) => (
          <g key={`line-${index}`}>
            <line
              x1={entry.x}
              y1={0}
              x2={entry.x}
              y2={entry.y}
              stroke="#888"
              strokeDasharray="3 3"
            />
          </g>
        ))}
      </>
    );
  };

  // Calculate padding for X-axis domain to add space on both sides
  const xDomainPadding = 0.5; // Adjust this value to increase/decrease padding
  const xDomainMin = data.length > 0 ? 0 - xDomainPadding : 0;
  const xDomainMax = data.length > 0 ? (data.length - 1) + xDomainPadding : 1;

  return (
    <div className={'relative flex'} style={{width: '100%', height: 260}}>
      <div className={'flex justify-between flex-col mr-1'} style={{
        paddingTop: 12,
        paddingBottom: 40
      }}>
        <p className={'h-5'}></p>
        <p className={'text-sm font-bold'}>{range[1]}</p>
        <p className={'text-sm font-bold'}>{range[0]}</p>
        <p className={'h-5'}></p>
      </div>
      <Bar style={{height: '100%', width: '126px'}}>
        <div className={'flex gap-2 bar'} style={{flexGrow: (max - range[1])}}>
          <ColorBar bgColor={'#da6561'}>
          </ColorBar>
          <p>Above range</p>
        </div>
        <div className={'flex gap-2 bar'} style={{flexGrow: (range[1] - range[0])}}>
          <ColorBar bgColor={'#55c575'}></ColorBar>
          <p>Optimal Range</p>
        </div>
        <div className={'flex gap-2 bar'} style={{flexGrow: (range[0] - min)}}>
          <ColorBar bgColor={'#da6561'}></ColorBar>
          <p>Below Range</p>
        </div>
      </Bar>
      <ResponsiveContainer width="100%" height={'100%'}>
        <ScatterChart
          margin={{
            top: 25,
            right: 60,
            bottom: 20, // Increased bottom margin for date labels
            left: -24,
          }}
        >
          <XAxis
            type="number"
            dataKey="x"
            tickFormatter={formatXAxis}
            domain={[xDomainMin, xDomainMax]} // Add padding to both sides
            ticks={customTicks}
            tick={{fontSize: 12}}
            tickLine={false}
            axisLine={false}
          />
          <YAxis
            type="number"
            dataKey="y"
            domain={[min, max]}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip
            cursor={{strokeDasharray: '3 3'}}
            formatter={(value, name, props) => {
              if (name === 'y') return props.payload.value;
              return null;
            }}
            labelFormatter={(value) => {
              const item = data.find(d => d.x === value);
              if (item) {
                return item.name;
              }
              return '';
            }}
          />

          {/* Add horizontal reference lines for optimal range */}
          <ReferenceLine
            y={range[0]}
            stroke="#55C5758C"
            strokeDasharray="3 3"
            strokeWidth={2}
            label={{
              position: 'left',
              fill: '#55c575',
              fontSize: 10
            }}
          />
          <ReferenceLine
            y={range[1]}
            stroke="#55C5758C"
            strokeDasharray="3 3"
            strokeWidth={2}
            label={{
              position: 'left',
              fill: '#55c575',
              fontSize: 10
            }}
          />

          <ReferenceLines/>
          <Scatter data={data} fill="black" line>
            <LabelList dataKey="value" position="top" fontSize={12} fontWeight={'bold'} offset={10}/>
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={isInRangeColor(entry.y)}/>
              ))
            }
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}

const ColorBar = styled.div`
    border-radius: 50px;
    height: 100%;
    border-left: 6px solid ${(props) => props.bgColor || '#da6561'};
`;

const Bar = styled.div`
    width: 300px;
    height: 400px;
    padding-top: 20px;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;

    .bar {
        flex: 1;
        border-bottom: 0.5px solid black;
        align-items: center;
        width: 100%;
        padding-top: 2px;
        padding-bottom: 2px;
        color: black;
        position: relative;

        p {
            font-size: 12px;
            font-weight: bold;
            color: black;
        }
    }
`;

const CustomizedShape = (props) => {
  const {cx, cy, fill, riderName} = props;
  return (
    <g>
      <Dot cx={cx} cy={cy} r={5} fill={fill}/>
      <g transform={`translate(${cx},${cy})`}>
        <text x={10} y={0} dy={4} textAnchor="left">{riderName}</text>
      </g>
    </g>
  );
};