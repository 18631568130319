import React, {useCallback, useContext, useEffect, useState} from 'react';
import {DashboardWrapper} from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {Header} from "../styles";
import {useNavigate, useParams} from "react-router-dom";
import {GeneralBody} from "../../../widgets/GeneralBody";
import api from "../../../utils/api";
import {ListItemDiv} from "../../../widgets/ListItem/ListItem.style";
import {format} from "date-fns";
import {SkinReportsContext} from "./SkinReportsContext";
import {useTranslation} from "react-i18next";

const PatientSkinReportPage = () => {
    const navigate = useNavigate();
    const skinReportContext = useContext(SkinReportsContext);
    const {patientId} = useParams();
    const [reports, setReports] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {t} = useTranslation('common');

    const fetchReports = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get(`/report/${patientId}`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch reports');
            }

            const data = await response.data;
            setReports(data['reports']);
            skinReportContext.setReports(data['reports']);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }, [patientId]);

    const openSkinReport = (reportId) => {
        navigate(`${reportId}`);
    }

    useEffect(() => {
        if (patientId) {
            fetchReports();
        }
    }, [patientId, fetchReports]);

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Glacies Skin Reports</h3>
            </Header>
            <GeneralBody>
                {
                    reports.map((report, index) => {
                        return (
                            <ListItemDiv
                                key={index}
                                onClick={() => openSkinReport(report['id'])}
                            >
                                <p>Report {++index}</p>
                                <h4>{report['id']}</h4>
                                <p>{format(report['created_at'], 'MMMM dd, yyyy H:mma')}</p>
                            </ListItemDiv>
                        );
                    })
                }
            </GeneralBody>
        </DashboardWrapper>
    );

}

export default PatientSkinReportPage;
