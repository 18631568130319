import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-wrap: wrap;

  
  @media (max-width: 500px) {
    margin-bottom: 0; /* Remove bottom margin for mobile screens */
  }

  @media (min-width: 501px) {
    & > input {
      margin-left: 48px;
    }
  }

  & > * {
    display: flex;
    align-items: center;
    flex: 1;

    @media (max-width: 500px) {
      flex: 0 1 100%;
      margin-top: 10px;

      &:last-child {
        & > div {
          justify-content: flex-start;
          margin-top: 5px;

          & > img {
            margin-left: 0;
          }
        }
      }
    }
      
    @media (min-width: 501px) {
      &:last-child {
        justify-content: center;
      }
    }
  }
`;

export const Select = styled.select`
    border-radius: 10px;
    border: 1px solid var(--Input-Stroke, #404D54);
    background: var(--Input-Primary, rgba(194, 233, 255, 0.12));
    border: 1px solid transparent;
    height: 43px;
    padding: 0 24px;
    margin-left: 48px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.40);
        line-height: normal;
    }

    &:focus {
        outline: none !important;
        border: 1px solid rgba(240, 216, 255, 0.70);
    }

    @media (max-width: 500px) {
        margin-left: 0;
    }
`;