import styled from "styled-components";

export const CanvasWrapper = styled.div`
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    //flex-wrap: wrap;
    justify-content: center;
    //align-items: center;
    gap: 20px;
    //background-color: #373249;
    //padding: 20px;
    border-radius: 10px;

    @media (max-width: 900px) {
        flex-direction: column;
    }

    #video-container {
        border-radius: 10px;
        overflow: hidden;
    }

    .webcam {
        border-radius: 10px;
    }

    .statusMessages {
        height: 100%;
    }

    .mirrorCanvas {
        canvas {
            transform: scaleX(-1);
        }
    }
`;
