import React from "react";
import {ProductInfoWrapper, ProductStepWrapper, StepWrapper,} from "./ReportProductStep.styles";
import mockUpImage from "../../assets/mock_tube.jpg";

const ReportProductStep = ({imgSrc, productName, step, onStep, isActive}) => {

  const onError = (e) => {
    e.target.onerror = null;
    e.target.src = mockUpImage;
  }

  return (
    <ProductStepWrapper isActive={isActive} onClick={onStep}>
      <ProductInfoWrapper>
        <img src={imgSrc} alt={productName} onError={onError}/>
        <p>{productName}</p>
      </ProductInfoWrapper>

      <StepWrapper>
        <p>{step}</p>
      </StepWrapper>
    </ProductStepWrapper>
  );
};

export default ReportProductStep;
