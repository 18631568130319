import React from "react";
import {skinTypeCombinations} from "../../assets/data/skin-type-combinations";
import {skinTypesDescription} from "../../assets/data/skin-types";
import Accordion from "../../widgets/accardion";
import SkinIndexDetails from "./SkinIndexDetails";
import SkinProfileWidgetShare from "./widget/skin-profile.widget";

const SkinProfileTab = ({report}) => {
    const getSkinTypeCombination = (name) => {
        return skinTypeCombinations.find((item) => item.name === name);
    };

    const filteredSkinTypeList = (skinTypes) => {
        if (!skinTypes) return [];
        return skinTypes?.map((type) => {
            return (
                skinTypesDescription?.find((item) => item.type === type) || {
                    type,
                    description: "No description available.",
                }
            );
        });
    };

    const skinIndex = (report?.data?.skin_index || "").toUpperCase();
    const skinTypeCombination = getSkinTypeCombination(skinIndex);

    return (
        <div
            className="flex flex-wrap gap-3 m-auto vertical-layout"
            style={{maxWidth: "1300px"}}
        >
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Your Skin Index"}
                    rightAction={<h1 className="font-bold">{skinIndex}</h1>}
                    children={
                        <SkinIndexDetails
                            description={skinTypeCombination?.description}
                            skinTypes={filteredSkinTypeList(skinTypeCombination?.types)}
                        />
                    }
                ></Accordion>
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Acne"}
                    rightAction={
                        <SkinProfileWidgetShare value={report["data"]["acne"]}/>
                    }
                    children={
                        <p>
                            Acne on the face is a common skin condition characterized by the
                            appearance of pimples, blackheads, and cysts, primarily caused by
                            the blockage and inflammation of hair follicles and sebaceous
                            glands, often exacerbated by hormonal changes and bacterial
                            overgrowth.
                            <br/>
                            <br/>
                            Based on your results, you have a{" "}
                            <strong>{report["data"]["acne"]}</strong> acne.
                        </p>
                    }
                ></Accordion>
                <Accordion
                    title={"Pigmentation"}
                    rightAction={
                        <SkinProfileWidgetShare value={report["data"]["pigmentation"]}/>
                    }
                    children={
                        <p>
                            Pigmentation or hyperpigmentation on the face refers to the
                            darkening of certain areas of skin caused by an excess production
                            of melanin, often triggered by factors like sun exposure, hormonal
                            changes, inflammation, or certain skin injuries or conditions
                            <br/>
                            <br/>
                            Based on your results, you have a{" "}
                            <strong>{report["data"]["pigmentation"]}</strong> pigmentation concerns.
                        </p>
                    }
                ></Accordion>
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Skin Type"}
                    rightAction={
                        <SkinProfileWidgetShare
                            title={"Skin Type"}
                            value={report["data"]["skin_type"]}
                        />
                    }
                    children={
                        <p>
                            A facial skin type is a classification based on the skin's
                            characteristics and behavior. Common skin types include normal,
                            oily, dry, and combination, each with distinct needs and responses
                            to environmental factors, skincare products, and routines.
                            <br/>
                            <br/>
                            Based on your results, you have a{" "}
                            <strong>{report["data"]["skin_type"]}</strong> skin type.
                        </p>
                    }
                ></Accordion>
                <Accordion
                    title={"Wrinkles"}
                    rightAction={
                        <SkinProfileWidgetShare value={report["data"]["wrinkles"]}/>
                    }
                    children={
                        <p>
                            Wrinkles, lines and creases in the face, are formed primarily due to aging processes, which
                            include the loss of skin elasticity and collagen. They can be exacerbated by sun exposure,
                            dehydration, and lifestyle habits. Wrinkles are a natural part of aging and can appear
                            commonly around the eyes, mouth, and forehead.
                            <br/>
                            <br/>
                            Based on your results, your wrinkles are on{" "}
                            <strong>{report["data"]["wrinkles"]}</strong> scale.
                        </p>
                    }
                ></Accordion>
            </div>
        </div>
    );
};

export default SkinProfileTab;
