import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

// Components
import DashboardHeader from "../../components/DashboardHeader";
import {DashboardInner, DashboardWrapper,} from "../../components/DashboardWrapper/DashboardWrapper.styles";
import Feature from "../../components/Feature";
import {
    Divider,
    DividerWrapper,
    FeaturesSectionWrapper,
    FeaturesWrapper,
    Section,
    SectionTitle
} from "../../components/FeaturesContainer/FeaturesContainer.styles";
import PatientProfile from "../../components/PatientProfile";

import MicrobiomeIcon from "../../assets/microbiome.svg";
import AddIcon from "../../assets/new-test.svg";

const PatientsCabinetPage = () => {
    const {patientId} = useParams();
    const {t} = useTranslation("common");

    return (
        <DashboardWrapper>
            <DashboardHeader/>
            <DashboardInner>
                <PatientProfile/>
                <FeaturesSectionWrapper>
                    <Section>
                        <SectionTitle align="left">
                            {t("get_started_in")}
                        </SectionTitle>
                        <FeaturesWrapper>
                            <Feature
                                image={AddIcon}
                                text={t("lifeStyle")}
                                isUrl={false}
                                redirect={"patient-survey"}
                            />

                            <Feature
                                image={AddIcon}
                                text={t("update_visual_analysis")}
                                redirect={"analysis"}
                                isUrl={false}
                            />

                            <Feature
                                image={AddIcon}
                                text={t("register_a_kit")}
                                redirect={"register-kit"}
                                isUrl={false}
                            />
                        </FeaturesWrapper>
                    </Section>
                    <DividerWrapper>
                        <Divider/>
                    </DividerWrapper>

                    <Section>
                        <SectionTitle align="right">{t("manage_reports")}</SectionTitle>
                        <FeaturesWrapper>
                            <Feature
                                image={MicrobiomeIcon}
                                text={t("request_new_report")}
                                redirect={`request-new-report`}
                                isUrl={false}
                            />
                            <Feature
                                image={MicrobiomeIcon}
                                text={t("all_reports")}
                                redirect={`skin-report`}
                                isUrl={false}
                            />
                        </FeaturesWrapper>
                    </Section>
                </FeaturesSectionWrapper>


            </DashboardInner>
        </DashboardWrapper>
    );
};

export default PatientsCabinetPage;
