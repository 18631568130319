import styled from "styled-components";

const FormContainer = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const FormWrapper = styled.div`
  background: rgba(88, 80, 109, 0.3);
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width:100%;
  backdrop-filter: blur(10px);
`;

const Title = styled.h2`
  text-align: center;
  color: white;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: white;
  margin-bottom: 5px;

    span {
    font-size: 12px;  
    color: rgba(255, 255, 255, 0.8); 
    margin-left: 5px; 
  }
`;

const Input = styled.input`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(72, 71, 78);
  color: white;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const SelectWrapper = styled.select`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: rgba(72, 71, 78);
  color: white;
  outline: none;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    borderRadius: "5px",
    background: "rgba(72,71,78,255)",
    color: "white",
    outline: "none",
  }),
  menu: (provided) => ({
    ...provided,
    background: "rgba(72,71,78,255)", // Same background for the dropdown
    borderRadius: "5px",
    backdropFilter: "blur(10px)",
    marginTop: "4px",
    maxHeight: "fit-content",
    overflowY: "auto", // Enables vertical scrolling // Optional: Adds a nice blur effect
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected
      ? "rgba(255, 255, 255, 0.8)"
      : "rgba(255, 255, 255, 0.2)", // Slightly transparent white for selected
    color: state.isSelected ? "black" : "white", // Black text when selected, white otherwise
    transition: "background 0.2s ease-in-out",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.6)", // Lighten background on hover
      color: "black", // Change text color on hover
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Ensure selected value is visible
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "rgba(255, 255, 255, 0.3)", // Background of selected items
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white", // Text color of selected items
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "red", // Change "X" icon color to red
    "&:hover": {
      backgroundColor: "rgba(255, 0, 0, 0.2)", // Light red hover effect
      color: "darkred", // Darker red on hover
    },
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Make the input text white while typing
  }),
};

const ErrorText = styled.p`
  color: red;
`;

export {
  FormContainer,
  FormWrapper,
  Title,
  FormGroup,
  Label,
  Input,
  SelectWrapper,
  Row,
  selectStyles,
  ErrorText
};
