import React from "react";
import {
    StyledMoonIcon,
    StyledSunIcon,
    StyledTag,
    TagText
} from "./tags.styles";

const TimeTag = ({ type, fullWidth = false, style }) => {
  const isMorning = type === "morning";

  return (
    <StyledTag $isMorning={isMorning} $fullWidth={fullWidth} style={style}>
      {isMorning ? <StyledSunIcon /> : <StyledMoonIcon />}
      <TagText>{isMorning ? "Morning" : "Evening"}</TagText>
    </StyledTag>
  );
};

export default TimeTag;
