import React, {useContext, useState} from "react";
import {Form, Header, Wrapper} from "./NewPatient.styles";
import AddClientImage from "../AddClientImage";
import Input from "../Input";
import {createPatient} from '../../utils/api-lists/patient.api';
import {UserContext} from "../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {Button} from "antd";
import {useTranslation} from "react-i18next";

const NewPatient = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState({type: null, message: null})
  const {user} = useContext(UserContext);
  const navigate = useNavigate();
  const {t} = useTranslation("common");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createPatient(user.id, firstName, lastName, email, phone);
      console.log(response);
      setStatus({type: "success", message: `Patient ${firstName} ${lastName} created successfully!`})
      navigate('/patient-list', {
        state: {
          message: `Patient ${firstName} ${lastName} created successfully!`,
          type: "success"
        }
      });
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
    } catch (err) {
      setStatus({type: 'error', message: 'Failed to create patient'})
    }
  };

  return (
    <Wrapper>
      <Header>
        <p>Register New Patient</p>
        <AddClientImage showText={true}/>
      </Header>
      <Form onSubmit={handleSubmit}>
        <Input type="text" name="name" placeholder='Name*' value={firstName}
               onChange={(e) => setFirstName(e.target.value)} required/>
        <Input type="text" name="surname" placeholder='Surname*' value={lastName}
               onChange={(e) => setLastName(e.target.value)} required/>
        <Input type="email" name="email" placeholder='Email' value={email}
               onChange={(e) => setEmail(e.target.value)}/>
        <Input type="number" name="phone" placeholder='Phone*' value={phone}
               onChange={(e) => setPhone(e.target.value)} required/>
        {status.type === "error" && <p style={{color: 'red'}}>{status.message}</p>}
        {status.type === "success" && <p style={{color: 'green'}}>{status.message}</p>}
        {/*<button type="submit" className="btn btn-2 btn-align-right">Submit</button>*/}
        <Button type="primary" htmlType="submit">{t("submit")}</Button>
      </Form>

    </Wrapper>
  )
};

export default NewPatient;
