import {ArcElement, Chart as ChartJS, Legend, Tooltip} from "chart.js";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {DashboardWrapper} from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import api from "../../../utils/api";
import {GeneralBody} from "../../../widgets/GeneralBody";
import FaqTab from "../../standalone/FaqTab";
import PersonalGrowTab from "../../standalone/PersonalGrow";
import SkinProfileTab from "../../standalone/SkinProfileTab";
import {Header} from "../styles";
import {useTranslation} from "react-i18next";
import HorizontalTab from "../../../components/HorizontalTab";
import Microbiome from "../../standalone/Microbiome";
import {Button, message, Tag} from "antd";
import {FaArrowLeft} from "react-icons/fa";
import {IconContext} from "react-icons";

ChartJS.register(ArcElement, Tooltip, Legend);

const PatientSkinReportDetailV2 = () => {
  const {reportId} = useParams();
  const [report, setReport] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isClipboardCopied, setIsClipboardCopied] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const {t} = useTranslation("common");

  const getTabContent = (tab, report) => {
    switch (tab) {
      default:
      case 0:
        return <Microbiome report={report}/>;
      case 1:
        return <SkinProfileTab report={report}/>;
      case 2:
        return <PersonalGrowTab report={report} products={products}/>;
      case 3:
        return <FaqTab report={report}/>;
    }
  };

  function shareReport() {
    const url = `${window.location.origin}/share/${reportId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setIsClipboardCopied(true);
        messageApi.open({
          type: 'success',
          content: t("copied_to_clipboard"),
        });
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
        messageApi.open({
          type: 'error',
          content: t("failed_copied_to_clipboard"),
        });
      });
  }

  async function approveReport() {
    // const data = {
    //   data: {
    //     ...this.reportForm.value,
    //   },
    //   finalize: finalize ?? false
    // }
    try {
      const data = {
        finalize: true,
      }
      const response = await api.patch(`/reports/${reportId}`, data);

      api.get(`/report_by_id/${reportId}`).then((data) => {
        setReport(data.data["report"]);
      });

      messageApi.open({
        type: 'success',
        content: t("report_approved"),
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: t("report_approved_failed"),
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    api.get(`/products`).then((response) => {
      setProducts(response.data["products"]);
      setLoading(false);
    });

    api.get(`/report_by_id/${reportId}`).then((data) => {
      setReport(data.data["report"]);
    });
  }, [reportId]);

  function getReportStatus() {
    switch (report["status"]) {
      case "FINAL":
        return "success";
      case "processing":
        return "processing";
      default:
        return "default";
    }
  }

  return (
    <DashboardWrapper>
      {contextHolder}
      <Header>
        <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
          <FaArrowLeft onClick={() => navigate(-1)}/>
        </IconContext.Provider>
        <h3>Skin Report [{reportId}]</h3>
        <div className="flex flex-row gap-4">
          {
            report && report["status"] && (
              <Tag color={getReportStatus()}><h3 className={"mr-3"}>{report["status"]}</h3></Tag>
            )
          }
          {
            report && report["status"] != "FINAL" &&
            <Button type="primary" onClick={approveReport}>{t("approve")}</Button>
          }
          <Button type="primary" onClick={shareReport}>{t("share")}</Button>
        </div>
      </Header>
      {report && report["data"] && products && !loading ? (
        <GeneralBody id="myPage">
          <TransitionGroup className="content-wrapper">
            <CSSTransition key={currentTab} classNames="content" timeout={500}>
              {getTabContent(currentTab, report)}
            </CSSTransition>
          </TransitionGroup>

          <div style={{height: 120}}></div>
          <HorizontalTab
            tabs={[t("microbiome"), t("skin_profile"), t("your_routine"), t("faq")]}
            currentTab={currentTab}
            onTabSelect={setCurrentTab}
          />

        </GeneralBody>
      ) : (
        <ClipLoader
          color={"#ffffff"}
          loading={true}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </DashboardWrapper>
  );
};

export default PatientSkinReportDetailV2;
