import { sk } from "date-fns/locale";
import React from "react";

const SkinIndexDetails = ({ description, skinTypes }) => {
  return (
    <div className="flex flex-col gap-3 pb-2">
      <p>{description}</p>
      <table className="border-collapse oswn-table">
        {skinTypes.map((skinType, index) => (
          <tr key={index} className="border-b border-b-black">
            <td>
              <h2 style={{ minWidth: "100px" }}>{skinType.type}</h2>
            </td>
            <td>
              <p style={{ fontSize: 8 }}>{skinType.description}</p>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default SkinIndexDetails;
