import React, {useContext, useState} from 'react';
import Input from "../../../components/Input";
import {Button} from "../../../components/PatientProfile/PatientProfile.styles";
import {RegisterKitBarcode} from "./register-kit-barcode-scanner";
import {UserContext} from "../../../context/UserContext";
import { useTranslation } from 'react-i18next';

const RegisterKitForm = ({handleSubmit}) => {
    const [barcode, setBarcode] = useState("");
    const userContext = useContext(UserContext);
    const {t} = useTranslation('common');

    function handleResultFromScanner(result) {
        setBarcode(result);
    }

    function submitForm(e) {
        e.preventDefault();
        handleSubmit(barcode);
    }

    return (
        <div>
            <form className={"flex flex-row gap-3 justify-center items-end mb-0 flex-wrap"}
                  onSubmit={submitForm}
            >
                <div className="flex flex-col items-center">
                    <label>{t("kit_barcode")}</label>
                    <Input name="query" value={barcode} onChange={(e) => setBarcode(e.target.value)}/>
                </div>
                <Button className="m-0" style={{margin: 0, minWidth: 60, maxWidth: 60}} type="submit">{t("add")}</Button>
            </form>
            <div className={"mt-3"}>
                <RegisterKitBarcode setCode={handleResultFromScanner}/>
            </div>
        </div>
    )
}

export default RegisterKitForm;