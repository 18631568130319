import React from "react"
import parabenfree_original from "../../assets/product_tags/parabenfree_original.png"
import animal_original from "../../assets/product_tags/animal_original.png"
import alcohol_original from "../../assets/product_tags/alcohol_original.png"
import ecofriendly_original from "../../assets/product_tags/ecofriendly_original.png"
import fragrancefree_original from "../../assets/product_tags/fragrancefree_original.png"
import noncomedogneic_original from "../../assets/product_tags/noncomedogneic_original.png"
import slsfree_original from "../../assets/product_tags/slsfree_original.png"
import green_$ from "../../assets/product_tags/$ (3).png"
import grey_$ from "../../assets/product_tags/envelope.png"

export const ReportProduct = ({
                                  tags, 
                                  price, 
                                  name, 
                                  product_img,
                              }) => {
    const product_tags = {
        "Paraben-free": parabenfree_original,
        "Not Animal-tested": animal_original,
        "Alcohol-free": alcohol_original,
        "Eco friendly": ecofriendly_original,
        "Fragrance-free": fragrancefree_original,
        "Non-Comodegenic": noncomedogneic_original,
        "Sls-free": slsfree_original,
    }

    const priceIcons = {
        low: [grey_$, grey_$, green_$],
        medium: [grey_$, green_$, green_$],
        high: [green_$, green_$, green_$],
    }


    return (
        <div className="p-4  px-5 ">
            <div className="flex justify-between">
            <div className="grid grid-cols-2 gap-1 h-full">
                    {tags?.map((tag) => (
                        <div className="inline" key={tag} style={{margin: 0, padding: 0}}>
                            <img src={product_tags[tag]} alt={tag} className="w-[30px]"/>
                        </div>
                    ))}
                </div>

                <div className="flex gap-1">
                    {priceIcons[price]?.map((icon, index) => (
                        <img
                            key={index}
                            src={icon}
                            alt={`price-${index}`}
                            className="w-[15x] h-[20px]"
                        />
                    ))}
                </div>
             
            </div>
            <div className=" flex justify-center items-center">
               
                <div
                    className="w-full h-full"
                    style={{width: "130px", height: "240px", padding: "10px"}}
                >
                    <img src={product_img} style={{
                        width: "100% !important",
                        height: "100%",
                        objectFit: "cover"
                    }}/>
                </div>
            </div>
            <span className="font-martel-sans block text-center">{name}</span>
        </div>
    )
}
