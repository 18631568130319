import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../context/UserContext"
import ProfileImage from "../assets/profile-img.svg";

// Components
import LogoAndText from "../components/LogoAndText"
import {
  Form,
  LeftContainer,
  RightContainer,
  SideWrapper,
  TextContainer,
} from "../components/SideWrapper/SideWrapper.styles"
import { login } from "../utils/api-lists/login.api"
import { getDoctor } from "../utils/api-lists/doctor.api"

const LoginPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation("common")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const { loginUser } = useContext(UserContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const user = await login(email, password)
      const doctor = await getDoctor(user.id)
      const profile_picture = doctor.profile_picture|| ProfileImage;
      loginUser({ ...user, profile_picture });
      navigate("/dashboard")
    } catch (err) {      
      setError(err.message)
    }
  }

  return (
    <SideWrapper>
      <LeftContainer>
        <LogoAndText />
      </LeftContainer>
      <RightContainer>
        <h2>{t("sign_in")}</h2>
        <TextContainer>
          <h3>{t("please_sign_in")}</h3>
        </TextContainer>
        <Form
          onSubmit={handleSubmit}
          style={{ marginTop: "121px", maxWidth: "400px" }}
        >
          <label>{t("email")}</label>
          <input
            placeholder={t("email")}
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>{t("password")}</label>
          <input
            placeholder={t("password")}
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p style={{ color: "red" }}>{t(error)}</p>}
          <button type="submit" className="btn btn-4">
            {t("next")}
          </button>
        </Form>
        <br />
        {/* <SignOptions signIn={true}/> */}
        <p>
          {t("forget_your_password")}{" "}
          <span onClick={() => navigate("/forgot-password")}>
            {t("forgot_password")}
          </span>
        </p>
      </RightContainer>
    </SideWrapper>
  )
}

export default LoginPage
